import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GoChevronDown } from "react-icons/go";

const StyledSelect = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  line-height: 1;
  cursor: pointer;
  gap: 5px;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  transition:0.3s;
  color: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--colored-text)'
            : props.$variant === 'secondary'
                ? 'var(--color-title)'
                : props.$variant === 'success'
                    ? 'var(--success-color)'
                    : props.$variant === 'danger'
                        ? 'var(--error-color)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-color)'
                            : 'var(--colored-text)'};
  background: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--nav-bg-hover)'
            : props.$variant === 'secondary'
                ? 'var(--secondary-color)'
                : props.$variant === 'success'
                    ? 'var(--success-bg)'
                    : props.$variant === 'danger'
                        ? 'var(--error-bg)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-bg)'
                            : 'var(--nav-bg-hover)'};
  border: none;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 50px;
  margin: 0;

  svg {
    transition: transform 0.3s ease;
    transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    ${(props) => !props.$selected && `
      &:not(:last-child) {
        transform: none;
      }
    `}
  }

  &:hover{
  background: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--nav-bg-hover)'
            : props.$variant === 'secondary'
                ? 'var(--secondary-color)'
                : props.$variant === 'success'
                    ? 'var(--success-bg-darker)'
                    : props.$variant === 'danger'
                        ? 'var(--error-bg-darker)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-bg-darker)'
                            : 'var(--color-title)'};
  }
`;

const OptionList = styled.ul`
  margin-top: 5px;
  z-index: 1;
  background: var(--bg-color);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
`;

const OptionItem = styled.li`
  line-height: 1;
  cursor: pointer;
  gap: 5px;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--colored-text)'
            : props.$variant === 'secondary'
                ? 'var(--color-title)'
                : props.$variant === 'success'
                    ? 'var(--success-color)'
                    : props.$variant === 'danger'
                        ? 'var(--error-color)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-color)'
                            : 'var(--color-title)'};
  background: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--nav-bg-hover)'
            : props.$variant === 'secondary'
                ? 'var(--secondary-color)'
                : props.$variant === 'success'
                    ? 'var(--success-bg)'
                    : props.$variant === 'danger'
                        ? 'var(--error-bg)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-bg)'
                            : 'var(--color-title)'};
  border: none;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 50px;
  margin: 0;
  transition:0.3s;

  &:hover{
  background: ${(props) =>
        props.$variant === 'primary'
            ? 'var(--nav-bg-hover)'
            : props.$variant === 'secondary'
                ? 'var(--secondary-color)'
                : props.$variant === 'success'
                    ? 'var(--success-bg-darker)'
                    : props.$variant === 'danger'
                        ? 'var(--error-bg-darker)'
                        : props.$variant === 'warning'
                            ? 'var(--warning-bg-darker)'
                            : 'var(--color-title)'};
  }
`;

const ColoredSelect = ({ options, onSelect, defaultText, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const selectRef = useRef(null);

    useEffect(() => {
        const selected = options.find((option) => option.id === value);
        setSelectedOption(selected || null);
    }, [value, options]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <StyledSelect ref={selectRef}>
            <SelectButton
                type="button"
                onClick={toggleDropdown}
                $variant={selectedOption ? selectedOption.variant : 'default'}
                $isOpen={isOpen}
            >
                {selectedOption ? (
                    <>
                        {selectedOption.icon && <selectedOption.icon />}
                        {selectedOption.label}
                    </>
                ) : value ? (
                    value
                ) : (
                    defaultText || 'Sélectionner...'
                )}
                <GoChevronDown />
            </SelectButton>
            <OptionList $isOpen={isOpen}>
                {options.map((option) => (
                    <OptionItem
                        key={option.id}
                        onClick={() => handleOptionClick(option)}
                        $variant={option.variant}
                    >
                        {option.icon && <option.icon />}
                        {option.label}
                    </OptionItem>
                ))}
            </OptionList>
        </StyledSelect>
    );
};

export default ColoredSelect;
