// Dans TopBar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import Toggle from './Toggle';
import styled from "styled-components";
import { useUser } from '../../../contexts/UserContext';

const TopBarContainer = styled.header`
  z-index: 10;
  margin-top: 30px;
  top: 30px;
  width: 100%;
  position: sticky;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`;

const TopBar = ({ toggleSidebar }) => {
    const { user, profilePictureURL } = useUser();

    return (
        <TopBarContainer>
            <Toggle onClick={toggleSidebar} />
            <NavLink to="/profil">
                {profilePictureURL ? (
                    <img src={profilePictureURL} className="user-picture" alt={user.profile.name} />
                ) : (
                    <img src="/illustration/svg/utilisateur.svg" className="user-picture" alt="avatar" />
                )}
            </NavLink>
        </TopBarContainer>
    );
};

export default TopBar;
