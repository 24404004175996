import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { applyTheme } from './Custom/Theme/theme.js';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from './contexts/UserContext.js';
import { DataProvider } from './contexts/DataContext';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import { default as ConfirmGlobal } from './components/confirm/ConfirmGlobal';
import { PopupProvider } from './contexts/PopupContext.js';
import { SideBarProvider } from './contexts/SideBarContext.js';

Amplify.configure(config);
applyTheme();

const Root = () => {


  return (
    <BrowserRouter>
      <UserProvider>
        <DataProvider>
          <PopupProvider>
            <SideBarProvider>
              <ToastContainer />
              <ConfirmGlobal />
              <App />
            </SideBarProvider>
          </PopupProvider>
        </DataProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
