import React from "react";
import styled from "styled-components";

// Styled component with shouldForwardProp configuration
const TitleComponent = styled.h1.withConfig({
  shouldForwardProp: (prop) => !['$variant', '$level'].includes(prop),
})`
  margin: 0;
  line-height: 1;
  color: ${(props) =>
    props.$variant === "default"
      ? "var(--color-title)"
      : props.$variant === "colored"
        ? "var(--main-color)"
        : props.$variant === "white"
          ? "#fff"
          : props.$variant === "black"
            ? "#151b49"
            : props.$variant === "danger"
              ? "var(--error-color)"
              : "var(--color-title)"};
  text-transform: ${(props) => props.$textTransform || "none"};
  font-size: ${(props) => {
    switch (props.$level) {
      case 1:
        return "30px";
      case 2:
        return "25px";
      case 3:
        return "23px";
      case 4:
        return "20px";
      case 5:
        return "19px";
      case 6:
        return "18px";
      default:
        return "16px";
    }
  }};
`;

const Title = ({ variant, level, className, id, onClick, children, textTransform, data_cy, ...restProps }) => {
  const HeadingTag = `h${level || 1}`;

  return (
    <TitleComponent
      as={HeadingTag}
      $textTransform={textTransform}
      $variant={variant}
      $level={level}
      className={`title ${className || ''}`}
      id={id}
      onClick={onClick}
      data-cy={data_cy}
      {...restProps}
    >
      {children}
    </TitleComponent>
  );
};

export default Title;
