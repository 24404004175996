import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import Button from '../../button/Button';
import Pastille from '../../textual/Pastille';
import Select from '../../form/Select';
import { SiMicrosoftexcel } from 'react-icons/si';
import { PiFilePdfDuotone } from 'react-icons/pi';
import Stack from '../../../box/container/Stack';
import { capitalizeFirstLetter } from '../../../../tools/capitalizeFirstLetter';
import { dateConverter } from '../../../../tools/dateConverter';
import { sanitizeSheetName } from '../../../../tools/sanitizeSheetName';

const options = [
    { id: 'excel', label: 'Exporter en CSV', icon: SiMicrosoftexcel },
    { id: 'pdf', label: 'Exporter en PDF', icon: PiFilePdfDuotone },
];

const ExportsAuditsPopup = ({ open, onClose, audits, clientName }) => {
    const [exportFormat, setExportFormat] = useState('');

    const exportToCSV = () => {
        const workbook = XLSX.utils.book_new();

        const usedSheetNames = new Set();

        audits.forEach((audit, index) => {
            const data = [];

            let sheetName = sanitizeSheetName(audit.name);

            if (sheetName.length > 31) {
                sheetName = `${sheetName.substring(0, 28)}...`;
            }

            let originalSheetName = sheetName;
            let counter = 1;
            while (usedSheetNames.has(sheetName)) {
                sheetName = `${originalSheetName.substring(0, 28 - counter.toString().length)}_${counter}`;
                counter++;
            }

            usedSheetNames.add(sheetName);

            if (Array.isArray(audit.tasks?.items)) {
                const sortedTasks = audit.tasks.items.sort((a, b) => new Date(a.date) - new Date(b.date));

                sortedTasks.forEach(task => {
                    data.push({
                        'Tâche': task.name,
                        'Description': task.description,
                        'Date': dateConverter(task.date, 'short'),
                        'Statut': task.done ? 'Terminée' : 'Non terminée'
                    });
                });
            }

            const worksheet = XLSX.utils.json_to_sheet(data);

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, `${capitalizeFirstLetter(clientName)}_audits_export.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(22);
        doc.text(`Liste des audits de ${capitalizeFirstLetter(clientName)}`, 10, 10);
        doc.setFontSize(12);
        doc.text(`Date d'exportation: ${dateConverter(new Date(), 'short')}`, 10, 20);
        doc.line(10, 25, 200, 25);

        let currentY = 35;

        audits.forEach((audit) => {
            const auditTitle = `${audit.name} (échéance le ${dateConverter(audit.targetDate)})`;
            const pageWidth = doc.internal.pageSize.getWidth();
            const splitTitle = doc.splitTextToSize(auditTitle, pageWidth - 20);

            splitTitle.forEach((line) => {
                doc.text(line, 10, currentY);
                currentY += 6;
            });

            currentY += 0;

            const tableColumn = ["Tâche", "Date", "Statut"];
            const tableRows = [];

            if (Array.isArray(audit.tasks?.items)) {
                const sortedTasks = audit.tasks.items.sort((a, b) => new Date(a.date) - new Date(b.date));

                sortedTasks.forEach(task => {
                    const taskData = [
                        task.name,
                        dateConverter(task.date, 'short'),
                        task.done ? "Terminée" : "Non terminée"
                    ];
                    tableRows.push(taskData);
                });
            }

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: currentY,
                margin: { top: 5 },
                styles: { cellPadding: 2, fontSize: 10, overflow: 'linebreak' },
                columnStyles: {
                    0: { cellWidth: 100 },
                },
            });

            currentY = doc.autoTable.previous.finalY + 10;

            if (currentY > 270) {
                doc.addPage();
                currentY = 10;
            }
        });

        doc.save(`${capitalizeFirstLetter(clientName)}_audits_export.pdf`);
    };


    const handleExport = () => {
        if (exportFormat === 'excel') {
            exportToCSV();
        } else if (exportFormat === 'pdf') {
            exportToPDF();
        }
    };

    useEffect(() => {
        if (!open) {
            setExportFormat('');
        }
    }, [open]);

    return (
        <Popup open={open} onClose={onClose} title={`Exporter ${audits.length} audit${audits.length > 1 ? 's' : ''} de ${capitalizeFirstLetter(clientName)}`} overflow={"visible"}>
            <Stack direction={"column"} spacing={"30px"}>
                {audits && audits.length > 0 ? (
                    <Stack flexWrap={"wrap"}>
                        {audits.map(audit => (
                            <Pastille variant={"success"} key={audit.id}>{audit.name}</Pastille>
                        ))}
                    </Stack>
                ) : (
                    <p>Aucun audit sélectionné.</p>
                )}
                <Stack direction={"column"}>
                    <Select
                        options={options}
                        onSelect={(selected) => setExportFormat(selected.id)}
                        defaultText="Sélectionner un format"
                    />
                    <Button variant="primary" width={"full-width"} onClick={handleExport} disabled={!exportFormat}>
                        {audits.length > 1 ? 'Exporter les audits' : 'Exporter l\'audit'}
                    </Button>
                </Stack>
            </Stack>
        </Popup>
    );
};

export default ExportsAuditsPopup;
