import React from 'react';
import styled from "styled-components";
import Text from "../components/ui/textual/Text";
import LoadingSpinner from './LoadingSpinner';

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default function Loading() {
  return (
      <Container>
        <LoadingSpinner/>
        <Text variant="white">Chargement...</Text>
      </Container>
  )
}
