function dateConverter(date, format = 'long') {
    const dateISO = new Date(date);
    
    if (isNaN(dateISO)) {
        throw new Error("Date invalide");
    }

    if (format === 'long') {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        let dateFormatee = dateISO.toLocaleDateString('fr-FR', options);
        
        if (dateISO.getDate() === 1) {
            dateFormatee = dateFormatee.replace('1', '1er');
        }
        
        return dateFormatee;
    } else if (format === 'short') {
        const jour = String(dateISO.getDate()).padStart(2, '0');
        const mois = String(dateISO.getMonth() + 1).padStart(2, '0');
        const annee = dateISO.getFullYear();
        
        return `${jour}/${mois}/${annee}`;
    } else {
        throw new Error("Format non reconnu. Utilisez 'long' ou 'jj/mm/yyyy'.");
    }
}

export { dateConverter };
