import React from 'react';

function Error500() {
    return (
        <>
            <p>Erreur 500 </p>
        </>
    );
}

export default Error500;
