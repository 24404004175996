import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const IconButtonComponent = styled.button`
  line-height: 1;
  justify-content: center;
  height: fit-content;
  text-align: center;
  gap: 5px;
  position: ${(props) => props.$position || "relative"};
  transform: ${(props) => props.$transform || ""};
  right: ${(props) => props.$right || ""};
  left: ${(props) => props.$left || ""};
  top: ${(props) => props.$top || ""};
  bottom: ${(props) => props.$bottom || ""};
  display: flex;
  align-items: center;
  width: ${(props) => props.$width || "fit-content"};
  color: ${(props) =>
    props.$variant === "action"
      ? "var(--success-color)"
      : props.$variant === "secondary-action"
      ? "var(--colored-text)"
      : props.$variant === "basique"
      ? "var(--paragraph)"
      : props.$variant === "danger"
      ? "var(--error-color)"
      : props.$variant === "warning"
      ? "var(--warning-color)"
      : "none"};
  background: ${(props) =>
    props.$variant === "action"
      ? "var(--success-bg)"
      : props.$enable === "notactive"
      ? "none"
      : "none"};
  border: none;
  padding: ${(props) =>
    props.$wtext === "yes"
      ? "10px 14px"
      : props.$wtext === "no"
      ? "10px"
      : "10px 14px"};
  font-size: ${(props) => props.$fontSize || "20px"};
  border-radius: ${(props) =>
    props.$wtext === "yes"
      ? "5px"
      : props.$wtext === "no"
      ? "50%"
      : "5px"};
  filter: ${(props) =>
    props.$enable === "active"
      ? "none"
      : props.$enable === "notactive"
      ? "grayscale(100%);"
      : "none"};
  cursor: ${(props) =>
    props.$enable === "active"
      ? "pointer"
      : props.$enable === "notactive"
      ? "default"
      : "pointer"};
  opacity: ${(props) =>
    props.$enable === "active"
      ? "1"
      : props.$enable === "notactive"
      ? "0.5"
      : "1"};
  transition: 0.3s;

  &:hover {
    background: ${(props) =>
      props.$variant === "action"
        ? "var(--success-bg-darker)"
        : props.$variant === "secondary-action"
        ? "var(--nav-bg-hover)"
        : props.$variant === "basique"
        ? "var(--nav-bg-hover)"
        : props.$enable === "notactive"
        ? "none"
        : props.$variant === "danger"
        ? "var(--error-bg)"
      : props.$variant === "warning"
      ? "var(--warning-bg)"
        : "none"};
  }

  &:active {
    background: ${(props) =>
      props.$variant === "action"
        ? "var(--success-bg-darker)"
        : props.$variant === "secondary-action"
        ? "var(--nav-bg-active)"
        : props.$variant === "basique"
        ? "var(--nav-bg-active)"
        : props.$enable === "notactive"
        ? "none"
        : props.$variant === "danger"
        ? "var(--error-bg-darker)"
      : props.$variant === "warning"
      ? "var(--warning-bg-darker)"
        : "none"};
  }
`;

const IconButton = ({
  type,
  variant,
  enable,
  wtext,
  width,
  className,
  id,
  onClick,
  to,
  fontSize,
  children,
  position,
  right,
  left,
  top,
  bottom,
  transform,
  ...props
}) => {
  const isLink = to !== undefined && to !== null;

  return (
    <IconButtonComponent
      as={isLink ? Link : "button"}
      to={isLink ? to : null}
      type={isLink ? null : (type ? (type === "input" ? ["button", "input"] : type) : "button")}
      $wtext={wtext}
      $variant={variant}
      $enable={enable}
      $width={width}
      className={className ? `btn-component ${className}` : "btn-component"}
      id={id}
      onClick={!isLink && enable !== "notactive" ? onClick : null}
      $position={position}
      $right={right}
      $left={left}
      $bottom={bottom}
      $top={top}
      $transform={transform}
      $fontSize={fontSize}
      {...props}
    >
      {children}
    </IconButtonComponent>
  );
};

export default IconButton;
