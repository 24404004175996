import React from 'react';
import TextInput from "./Textinput";
import { useNavigate } from 'react-router-dom';
import Button from "../button/Button";
import { notifyError } from "../Toastify";
import { useData } from '../../../contexts/DataContext';
import Textarea from "../../../components/ui/form/Textarea";
import IconButton from "../button/IconButton";
import Stack from '../../box/container/Stack';
import Form from './Form';

export default function CreateClientForm({togglePopup}) {
  const {formData, setFormData, handleInputChange, create, setPressed} = useData();
  const navigate = useNavigate();
  const cleanForm = {
    email: "",
    name: "",
    description: "",
  };

  const handleSubmit = async () => {
    if (!formData.email || !formData.name) {
      notifyError("Veuillez remplir les champs obligatoires");
      return;
    } else {
      try {
        await create();
        setPressed(true);
        setFormData(cleanForm);
        navigate('/clients');
      } catch (error) {
        console.log("error on creating client", error);
      }
    }
  }


  return (
    <Form>
      <Stack direction="column">
      <IconButton variant="secondary-action" to="/clients">Retour</IconButton>
        <TextInput
          type="email"
          label={"Email*"}
          value={formData.email}
          name={"email"}
          onChange={handleInputChange}
          required
          variant={"blue"}
        />
        <TextInput
          type="text"
          label={"Nom*"}
          value={formData.name}
          name={"name"}
          onChange={handleInputChange}
          required
          variant={"blue"}
        />
        <Textarea
          maxLength={250}
          label={"Description"}
          name={"description"}
          value={formData.description}
          onChange={handleInputChange}
          variant={"blue"}
        />
        <Button onClick={handleSubmit} variant={"primary"} width="full-width">
          Créer un client
        </Button>
      </Stack>
    </Form>
  )
}
