export function handlerVariantTaskTemplateDaysBefore(daysBeforeTargetDay) {
    let variant = 'primary';
    if (daysBeforeTargetDay >= 0 && daysBeforeTargetDay <= 25) {
        variant = 'success';
    } else if (daysBeforeTargetDay > 25 && daysBeforeTargetDay <= 50) {
        variant = 'primary';
    } else if (daysBeforeTargetDay > 50 && daysBeforeTargetDay <= 80) {
        variant = 'warning';
    } else if (daysBeforeTargetDay > 80) {
        variant = 'danger';
    }

    return variant;
}
