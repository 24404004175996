import React from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import CreateAuditTemplate from '../../form/CreateAuditTemplate';
import Tips from '../../textual/Tips';

const AddAuditTemplatePopup = ({ open, onClose }) => {
    return (
        <Popup open={open} onClose={onClose} title={"Ajouter un template d'audit"}>
            <Text>Un template d'audit est un modèle que vous pouvez appliquer à chaque client, évitant ainsi de devoir le recréer entièrement à chaque fois.</Text>
            <Tips variant="success">Tips : vous pourrez attribuer des tâches à ce template après l'avoir créé.</Tips>
            <CreateAuditTemplate onClose={onClose} />
        </Popup>
    );
};

export default AddAuditTemplatePopup;

