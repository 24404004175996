import React from 'react';
import Title from '../../components/ui/textual/Title';
import Text from '../../components/ui/textual/Text';
import CurrentDate from '../../components/ui/dynamic/CurrentDate';
import Hero from '../../components/box/section/Hero';
import Container from '../../components/box/container/Container';
import HighLightContainer from '../../components/box/container/HighLightContainer';
import Column from '../../components/box/container/Column';
import { useUser } from '../../contexts/UserContext';
import Bento from '../../components/box/bento/Bento'
import Stack from '../../components/box/container/Stack'
import { useData } from '../../contexts/DataContext';
import Link from '../../components/ui/textual/Link'

function Home() {
  const { user } = useUser();
  const {
    tasks,
    audits,
    allClientsCount
  } = useData();

  return (
      <Hero spacing="30px">
        <Container variant="normal" direction="row" width="100%" justify="space-between">
          <Column width="65%">
            <Title level={3}>
              Tableau de bord
            </Title>
            <Text>
              <CurrentDate highlight="day" uppercase="true" variant="long" />
            </Text>
          </Column>
        </Container>
        <HighLightContainer variant="primary" direction="row" width="100%" justify="space-between"
          animationType={"fadeIn"}>
          <Column width="35%">
            <img className='image-container' src='/illustration/svg/objectif.svg'
              alt="illustration d'un personnage"></img>
          </Column>
          <Column width="65%" justify="center">
            {user && (
              <Title level={1} variant="blue">
                Bienvenue {user.profile.name}
              </Title>
            )}
            <Text>
              Passez une agréable journée
            </Text>
          </Column>
        </HighLightContainer>
        <Container direction="row">
          <Column width="33.3%">
            <Bento highlight="highlight">
              <Stack justify="space-between" align="center">
                <Stack direction="column">
                  <Text>Total des clients</Text>
                  {allClientsCount && allClientsCount !== 0 ? (
                    <Title level={2}><Link to={"/clients"}>{allClientsCount}</Link></Title>
                  ) : (
                    <p>Aucun client disponible</p>
                  )}
                </Stack>
                <img className='user-picture' src='/illustration/svg/objectif.svg'
                  alt="illustration d'un personnage"></img>
              </Stack>
            </Bento>
          </Column>
          <Column width="33.3%">
            <Bento highlight="highlight">
              <Stack justify="space-between" align="center">
                <Stack direction="column">
                  <Text>Total d'audits</Text>
                  {audits.length && audits.length !== 0 ? (
                    <Title level={2}><Link to={"/clients"}>{audits.length}</Link></Title>
                  ) : (
                    <p>Aucun audit disponible</p>
                  )}
                </Stack>
                <img className='user-picture' src='/illustration/svg/objectif.svg'
                  alt="illustration d'un personnage"></img>
              </Stack>
            </Bento>
          </Column>
          <Column width="33.3%">
            <Bento highlight="highlight">
              <Stack justify="space-between" align="center">
                <Stack direction="column">
                  <Text>Total des taches</Text>
                  {tasks.length && tasks.length !== 0 ? (
                    <Title level={2}><Link to={"/clients"}>{tasks.length}</Link></Title>
                  ) : (
                    <p>Aucune tâche disponible</p>
                  )}
                </Stack>
                <img className='user-picture' src='/illustration/svg/objectif.svg'
                  alt="illustration d'un personnage"></img>
              </Stack>
            </Bento>
          </Column>
        </Container>
      </Hero>
  );
}

export default Home;
