import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.section`
max-width: 1200px;
gap: ${props => props.$spacing || ""};
margin: ${props => props.$margin || ""};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc( 30vh - 100px - 80px);
    width: 100%;
`;

function Section({ children, spacing, margin }) {
    return (
        <SectionContainer $spacing={spacing} $margin={margin}>
            {children}
        </SectionContainer>
    );
}

export default Section;
