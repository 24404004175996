import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import Hero from '../../../components/box/section/Hero';
import Title from '../../../components/ui/textual/Title';
import Bento from '../../../components/box/bento/Bento';
import Stack from '../../../components/box/container/Stack';
import Text from '../../../components/ui/textual/Text';
import IconButton from '../../../components/ui/button/IconButton';
import { deleteUser, signOut } from 'aws-amplify/auth';
import { confirm } from "../../../components/confirm/ConfirmGlobal";
import {
    notifySuccess,
    notifyError,
} from '../../../components/ui/Toastify';
import FormError from '../../../components/ui/form/FormError';
import { useUser } from '../../../contexts/UserContext';
import { CiTrash } from 'react-icons/ci';
import { deleteProfile } from '../../../graphqlCustom/mutationsCustom'

const client = generateClient();

function Setting() {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const { logout, user } = useUser();

    const handleDeleteCurrentProfile = async () => {
        try {
            await client.graphql({
                query: deleteProfile,
                variables: {
                    input: {
                        id: user.id,
                    }
                }
            });
        } catch (error) {
            console.error('Erreur lors de la suppréssion du profile.', error);
        }
    };

    const handleDeleteCurrentAccount = async () => {
        if (await confirm({
            title: "Voulez-vous vraiment supprimer votre compte ?",
            content: "Votre compte sera définitivement supprimé et vous ne pourrez pas le récupérer.",
            variant: "danger"
        })) {
            try {
                await handleDeleteCurrentProfile();
                await deleteUser();
                await signOut();
                logout();
                navigate('/se-connecter');
                notifySuccess("Compte supprimé avec succès");
            } catch (error) {
                console.error('Erreur lors de la suppréssion du compte.', error);
                setError("Erreur lors de la suppréssion du compte.");
                notifyError("La suppréssion a échouée");
            }
        }
    };

    return (
        <Hero>
            <Title level={1}>
                Paramètres
            </Title>
            <Bento>
                <Stack separator>
                    <Title level={3} variant="danger">
                        Supprimer votre compte
                    </Title>
                </Stack>
                <Stack spacing="20px" direction="column">
                    <Text>
                        Vous pouvez supprimer votre compte, mais cette action est définitive.
                    </Text>
                    <IconButton variant="danger" onClick={handleDeleteCurrentAccount}><CiTrash /> Supprimer mon compte</IconButton>
                    {error && <FormError variant="error">{error}</FormError>}
                </Stack>
            </Bento>
        </Hero>
    );
}

export default Setting;