import React, { useState } from 'react';
import styled from 'styled-components';
import TextInput from './Textinput';
import DateInput from './DateInput';
import Button from '../button/Button';
import * as Tool from '../../../tools/Calculate';
import { useData } from '../../../contexts/DataContext';
import { useParams, useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../Toastify';
import Stack from '../../box/container/Stack';
import IconButton from '../button/IconButton';
import Tips from '../textual/Tips';
import Accordion from '../Accordion'
import { CiTrash } from 'react-icons/ci';
import { confirm } from "../../confirm/ConfirmGlobal";
import { MdAdd } from 'react-icons/md';
import Textarea from './Textarea';

const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`;

const Dot = styled.div`
  width: ${(props) => (props.$active ? '16px' : '12px')};
  height: 6px;
  border-radius: 30px;
  background-color: ${(props) => (props.$active ? 'var(--main-color)' : 'var(--grey-color)')};
`;

const StepNavigation = ({ currentStep, handleNextStep, handlePrevStep, handleCreateNewAudit, nameAudit, typeAudit }) => {
  const isNameTypeFilled = nameAudit && typeAudit;
  console.log(isNameTypeFilled)
  const renderActions = () => {
    switch (currentStep) {
      case 1:
        return (
          <Button variant="primary" onClick={handleNextStep} disable={!isNameTypeFilled}>
            Suivant
          </Button>
        );
      case 2:
        return (
          <Button variant="primary" onClick={handleNextStep}>
            Suivant
          </Button>
        );
      case 3:
        return (
          <Button variant="primary" onClick={handleNextStep}>
            Suivant
          </Button>
        );
      case 4:
        return (
          <Button variant="primary" onClick={handleCreateNewAudit}>
            Créer le nouvel Audit
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Stack justify="space-between" align="center" width="100%">
      <Stack direction="row" justify="end" align="center" width="100%">
        {currentStep > 1 && (
          <IconButton variant="basique" onClick={handlePrevStep}>
            Retour
          </IconButton>
        )}
        {renderActions()}
      </Stack>
    </Stack>
  );
};

const FormCreateAuditNoTemplate = ({ auditTemplates, client }) => {
  const navigate = useNavigate();
  const { createAnAudit, createATask } = useData();
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(1);
  const [nameAudit, setNameAudit] = useState('');
  const [typeAudit, setTypeAudit] = useState('');
  const [date, setDate] = useState('');
  const [tasks, setTasks] = useState([]);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleAddTask = () => {
    setTasks([...tasks, { name: '', date: '', description: '' }]);
  };

  const handleTaskNameChange = (index, value) => {
    const newTasks = [...tasks];
    newTasks[index].name = value;
    setTasks(newTasks);
  };

  const handleTaskDescripitionChange = (index, value) => {
    const newTasks = [...tasks];
    newTasks[index].description = value;
    setTasks(newTasks);
  };

  const handleTaskDateChange = (index, value) => {
    const newTasks = [...tasks];
    newTasks[index].date = value;
    setTasks(newTasks);
  };

  const handleRemoveTask = async (index) => {
    try {
      if (await confirm({
        title: "Voulez-vous vraiment supprimer cette tâche ?",
        content: "Cette tâche sera supprimée de cet audit.",
        variant: "danger"
      })) {
        const updatedTasks = [...tasks];
        updatedTasks.splice(index, 1);
        setTasks(updatedTasks);
        notifySuccess('Tâche supprimée');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la tâche :', error);
      notifyError('Erreur lors de la suppression de la tâche');
    }
  };

  const handleCreateNewAudit = async () => {
    if (!nameAudit || !typeAudit || !date) {
      notifyError('Veuillez remplir tous les champs obligatoires');
      return;
    }

    const formattedDate = await Tool.dateToAWSFormat(date);

    try {
      const newAudit = await createAnAudit(id, nameAudit, 'En cours', formattedDate, typeAudit);
      const newAuditId = newAudit.data.createAudit.id;

      await Promise.all(
        tasks.map(async (task, index) => {
          const formattedTaskDate = await Tool.dateToAWSFormat(task.date);
          await createATask(newAuditId, index.toString(), task.name, formattedTaskDate, task.description);
        })
      );

      notifySuccess('Audit créé avec succès');
      navigate(`/clients/${client.id}`);
    } catch (error) {
      console.error('Erreur lors de la création de l\'audit :', error);
      notifyError('Erreur lors de la création de l\'audit');
    }
  };

  return (
    <Stack direction="column">
      <StepIndicator>
        {[...Array(4)].map((_, index) => (
          <Dot key={index} $active={currentStep === index + 1} />
        ))}
      </StepIndicator>

      {currentStep === 1 && (
        <Stack direction="column" animationType="fadeIn">
          <Tips variant="success">1- Entrez le nom et le type de l'audit.</Tips>
          <TextInput
            type="text"
            label="Nom de l'audit*"
            value={nameAudit}
            onChange={(e) => setNameAudit(e.target.value)}
            variant="blue"
          />
          <TextInput
            type="text"
            label="Type d'audit*"
            value={typeAudit}
            onChange={(e) => setTypeAudit(e.target.value)}
            variant="blue"
          />
        </Stack>
      )}

      {currentStep === 2 && (
        <Stack direction="column" animationType="fadeIn">
          <Tips variant="success">2- Entrez la date butoir de l'audit.</Tips>
          <DateInput
            type="text"
            label="Date butoir*"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="blue"
          />
        </Stack>
      )}

      {currentStep === 3 && (
        <Stack direction="column" animationType="fadeIn">
          <Tips variant="success">3- Ajoutez les tâches de l'audit.</Tips>
          {tasks.map((task, index) => (
            <Stack>
              <Accordion key={index} title={`Tâche ${index + 1} : ${task.name}`} defaultOpen={true}>
                <Stack direction="column">
                  <TextInput
                    type="text"
                    label={`Nom de la tâche ${index + 1}`}
                    value={task.name}
                    onChange={(e) => handleTaskNameChange(index, e.target.value)}
                    variant="blue"
                  />
                  <DateInput
                    type="text"
                    label={`Date de la tâche ${index + 1}`}
                    value={task.date}
                    onChange={(e) => handleTaskDateChange(index, e.target.value)}
                    variant="blue"
                  />
                  <Textarea
                    variant={"blue"}
                    label={`Description`}
                    value={task.description}
                    onChange={(e) => handleTaskDescripitionChange(index, e.target.value)}
                  />
                </Stack>
              </Accordion>
              <IconButton variant="danger" onClick={() => handleRemoveTask(index)}>
                <CiTrash />
              </IconButton>
            </Stack>

          ))}
          <IconButton variant="action" onClick={handleAddTask}>
            <MdAdd />
            Ajouter une tâche
          </IconButton>
        </Stack>
      )}

      {currentStep === 4 && (
        <Stack direction="column" animationType="fadeIn">
          <Tips variant="success">4- Simulation des tâches de l'audit.</Tips>
          {tasks.map((task, index) => (
            <DateInput
              key={index}
              type="text"
              label={task.name}
              value={task.date}
              onChange={(e) => handleTaskDateChange(index, e.target.value)}
              variant="blue"
            />
          ))}
        </Stack>
      )}

      <StepNavigation
        currentStep={currentStep}
        nameAudit={nameAudit}
        typeAudit={typeAudit}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        handleCreateNewAudit={handleCreateNewAudit}
      />
    </Stack>
  );
};

export default FormCreateAuditNoTemplate;
