import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import TextInput from '../../form/Textinput';
import Textarea from '../../form/Textarea';
import DateInput from '../../form/DateInput';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifySuccess } from '../../Toastify';
import { dateToAWSFormat } from '../../../../tools/Calculate';


const EditTaskPopup = ({ open, onClose, task }) => {
    const { editATask, refresh, setRefresh } = useData();
    const [error, setError] = useState(null);
    const [taskName, setTaskName] = useState(task?.name);
    const [taskDate, setTaskDate] = useState(task?.date);
    const [taskDescription, setTaskDescription] = useState(task?.description);


    const handleSubmit = async () => {
        if (taskName && taskDate) {
            try {
                const formattedDate = await dateToAWSFormat(taskDate);
                await editATask(task.id, taskName, formattedDate, taskDescription);
                setRefresh(!refresh);
                onClose();
                notifySuccess('La tâche a été mise à jour')
            } catch (error) {
                console.error("Erreur lors de la modification de la tâche :", error);
            }
        } else {
            alert("Veuillez remplir tous les champs requis.");
        }
    };

    if(!task){
        setError("Aucune tâche")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Modifier la tâche ${task?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : task ? (
                <>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de la tâche"}
                                value={taskName}
                                onChange={(e) => setTaskName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <DateInput
                                type={"text"}
                                label={"Date de la tâche"}
                                value={taskDate}
                                onChange={(e) => setTaskDate(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <Textarea
                                variant={"blue"}
                                label={"Description"}
                                value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)}
                                maxCharCount={250}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default EditTaskPopup;
