export function handleVariantDocumentType(type) {
    let variant = 'primary';
    if (type && typeof type === 'string') {
        if (type.includes("image")) {
            variant = 'success';
        } else if (type.includes("pdf")) {
            variant = 'danger';
        } else if (type.includes("text") || type.includes("plain")) {
            variant = 'primary';
        } else if (type.includes("video")) {
            variant = 'warning';
        } else if (type.includes("application") && type.includes("msword")) {
            variant = 'primary';
        } else if (type.includes("application") && type.includes("vnd.ms-excel")) {
            variant = 'success';
        }
    }
    return variant;
}
