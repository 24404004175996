import React, { useState } from 'react';
import { useData } from "../../../contexts/DataContext";
import TextInput from "./Textinput";
import Button from "../button/Button";
import { notifyError, notifySuccess } from "../Toastify";
import Stack from '../../box/container/Stack';

export default function CreateAuditTemplate(props) {
  const { onClose } = props;
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [cycle, setCycle] = useState('');

  const { createAnAuditTemplate, setRefresh } = useData();

  async function handleAuditTemplate() {
    try {
      if (!name || !type || !cycle) {
        throw new Error('Veuillez remplir tous les champs');
      }
      if (isNaN(cycle)) {
        throw new Error('Le cycle doit être un nombre entier');
      }

      await createAnAuditTemplate(name, type, cycle);

      notifySuccess('Audit template créé');
      setRefresh((prev) => !prev);
      onClose();
    } catch (error) {
      notifyError(error.message);
      console.error('Erreur lors de la création du template d\'audit :', error);
    }
  }



  return (

    <Stack direction={"column"} spacing={"15px"}>
      <Stack direction={"column"}>
        <TextInput
          type={"text"}
          label={"Nom du template audit"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant={"blue"}
        />
        <TextInput
          type={"text"}
          label={"type du template audit"}
          value={type}
          onChange={(e) => setType(e.target.value)}
          required
          variant={"blue"}
        />
        <TextInput
          type={"text"}
          label={"Cycle du template (en jours)"}
          value={cycle}
          onChange={(e) => setCycle(e.target.value)}
          required
          variant={"blue"}
        />
      </Stack>
      <Button variant={"primary"} width="full-width" onClick={handleAuditTemplate}>Créer le template</Button>
    </Stack>
  )
}
