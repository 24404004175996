import React, { useState } from 'react';
import { useData } from '../../../contexts/DataContext';
import TextInput from "./Textinput";
import Button from "../button/Button";
import { notifyError } from "../Toastify";
import Stack from '../../box/container/Stack';
import Textarea from './Textarea';
import UploadDocument from '../UploadDocument';
import { uploadData } from 'aws-amplify/storage';
import { useUser } from '../../../contexts/UserContext';

export default function CreateDocument(props) {
    const { onClose } = props;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const { createADocument, refresh, setRefresh } = useData();
    const { user } = useUser();

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const uploadFileToS3 = async () => {
        if (!selectedFile) {
            throw new Error("Aucun fichier sélectionné");
        }

        const key = `documents/${selectedFile.name}`;
        try {
            await uploadData({
                path: `protected/${user.identityId}/${key}`,
                data: selectedFile,
                options: {
                    contentType: selectedFile.type,
                  },
            });
            return { key, identityId: user.identityId };
        } catch (error) {
            throw new Error("Erreur lors de l'upload du fichier");
        }
    };

    async function handleTaskTemplateCreation() {
        if (!name || !selectedFile) {
            notifyError('Veuillez remplir tous les champs et importer un document');
            return;
        }

        try {
            const { key, identityId } = await uploadFileToS3();

            await createADocument(name, description, key, identityId);
            setRefresh(!refresh);
            onClose();
        } catch (error) {
            console.error('Erreur lors de la création du document :', error);
            notifyError(error.message || 'Erreur lors de la création du document');
        }
    }

    return (
        <Stack direction={"column"} spacing={"15px"}>
            <Stack direction={"column"}>
                <TextInput
                    type="text"
                    label="Nom"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    variant={"blue"}
                />
                <Textarea
                    variant={"blue"}
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Stack>
            <UploadDocument onFileSelect={handleFileSelect} maxSize={10 * 1048576} acceptedTypes="image/png, image/jpeg, image/jpg, image/PNG, application/pdf, text/plain, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-powerpoint"/>
            <Button width="full-width" variant={"primary"} onClick={handleTaskTemplateCreation}>
                Créer le document
            </Button>
        </Stack>
    );
}
