import React, { useEffect, useState } from 'react';
import { useData } from '../../../contexts/DataContext';
import { useParams, useNavigate } from "react-router-dom";
import BackgroundImageContainer from "../../../components/box/container/BackgroundImageContainer";
import Title from "../../../components/ui/textual/Title";
import Text from "../../../components/ui/textual/Text";
import Section from "../../../components/box/section/Section";
import Button from "../../../components/ui/button/Button";
import Loading from "../../../states/Loading";
import Bento from "../../../components/box/bento/Bento";
import Column from "../../../components/box/container/Column";
import IconButton from "../../../components/ui/button/IconButton";
import { CiTrash } from "react-icons/ci";
import Container from "../../../components/box/container/Container";
import TextInput from "../../../components/ui/form/Textinput";
import Textarea from "../../../components/ui/form/Textarea";
import { notifyError, notifySuccess } from "../../../components/ui/Toastify";
import { confirm } from "../../../components/confirm/ConfirmGlobal";
import Stack from '../../../components/box/container/Stack';
import { useUser } from '../../../contexts/UserContext';
import fetchS3File from '../../../hooks/getdata/fetchs3File';
import TextHover from "../../../components/ui/hover/TextHover";
import { usePopup } from '../../../contexts/PopupContext';
import ClientLogoPopup from '../../../components/ui/popup/allPopups/ClientLogoPopup.js';
import {
    updateClients,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import NotFound from '../../../states/NotFound';
import Span from '../../../components/ui/textual/Span';
import { getClients } from '../../../graphqlCustom/queriesCustom';

const client = generateClient();

export default function ClientDetails({ onProgressChange, onUploadStart, onUploadEnd }) {
    const { popups, openPopup, closePopup } = usePopup();
    const navigate = useNavigate();
    const { isAdmin } = useUser();
    const [clientDetails, setClientDetails] = useState(null);
    const [logo, setLogo] = useState(null);
    const { deleteClient, setPressed } = useData();
    const [loading, setLoading] = useState(true);

    const [localFormData, setLocalFormData] = useState({
        email: "",
        name: "",
        description: "",
    });

    const { id } = useParams();

    useEffect(() => {
        async function fetchClient() {
            setLoading(true);
            if (id) {
                try {
                    const client = await getClient(id);
                    if (client) {
                        setClientDetails(client);
                        setLocalFormData(client);
                        if (client.logo && !logo) {
                            const logoUrl = await fetchS3File(client.logo, client.logoIdentityId);
                            setLogo(logoUrl);
                        }
                    } else {
                        setClientDetails(null);
                    }
                } catch (error) {
                    console.error("Client non trouvé", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        fetchClient();
    }, [id, logo]);

    const handleLocalInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormData({
            ...localFormData,
            [name]: value
        });
    };

    async function updateClient(id) {
        try {
            const updatedClient = await client.graphql({
                query: updateClients,
                variables: {
                    input: {
                        id: id,
                        "email": localFormData.email.toLowerCase(),
                        "name": localFormData.name.toLowerCase(),
                        "description": localFormData.description,
                    }
                }
            });

            notifySuccess("Client mis à jour avec succès");
            console.log("Client mis à jour :", updatedClient);
        } catch (error) {
            notifyError("Erreur lors de la mise à jour du client");
            console.error("Erreur lors de la mise à jour du client :", error);
        }
    }

    async function handleSubmit() {
        if (!localFormData.email || !localFormData.name) {
            notifyError("Veuillez remplir les champs obligatoires");
            return;
        }
        try {
            await updateClient(localFormData.id);
            setPressed(true);
            navigate('/clients');
        } catch (error) {
            console.error("Erreur lors de la mise à jour du client", error);
            notifyError("Erreur lors de la mise à jour du client");
        }
    }

    async function handleDeleteClient() {
        if (await confirm({
            title: "Voulez-vous vraiment supprimer ce client ?",
            content: "Ce client sera supprimé définitivement et personne ne pourra y accéder",
            variant: "danger"
        })) {
            try {
                await deleteClient(localFormData.id);
                setPressed(true);
                notifySuccess("Client supprimé avec succès");
                navigate('/clients');
            } catch (error) {
                console.error("Erreur lors de la suppression du client", error);
                notifyError("Erreur lors de la suppression du client");
            }
        }
    }

    async function getClient(id) {
        try {
            const clientTarget = await client.graphql({
                query: getClients,
                variables: { id: id }
            });
            return clientTarget.data.getClients;
        } catch (error) {
            console.error("Erreur lors de la récupération du client :", error);
            return null;
        }
    }

    if (!clientDetails && !loading) {
        return (
            <NotFound />
        );
    }

    if (!clientDetails && loading) {
        return (
            <Loading />
        );
    }

    if (!isAdmin) {
        return (
            <NotFound />
        );
    }

    if (clientDetails) return (
        <>
            <Section>
                <BackgroundImageContainer coverUrl="">
                    <div className="profil-container">
                        <TextHover
                            text={"Modifier le logo"}
                            onClick={() => openPopup('popupLogoClient')}
                        >
                            <img
                                src={logo || "/illustration/svg/utilisateur.svg"}
                                className="user-picture"
                                alt={localFormData.name || "avatar"}
                            />
                        </TextHover>
                        <div className="user-info">
                            <div>
                                <Title level={3} variant="white">
                                    Vous modifiez <Span textTransform={"capitalize"}>{clientDetails?.name}</Span>
                                </Title>
                                <Text variant="white"> Client id : {clientDetails?.id}</Text>
                            </div>
                        </div>
                    </div>
                </BackgroundImageContainer>
            </Section>
            <Section>
                <Container direction="row" align="start" width="100%">
                    <Column width={"100%"} gap="20px">
                        <IconButton to={`/clients/${localFormData.id}`} variant="secondary-action">Voir la fiche client</IconButton>
                        <Bento highlight={"highlight"}>
                            <Title level={4}>Description</Title>
                            <TextInput
                                type="email"
                                label={"Email*"}
                                value={localFormData.email}
                                name={"email"}
                                onChange={handleLocalInputChange}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type="text"
                                label={"Nom*"}
                                value={localFormData.name}
                                name={"name"}
                                onChange={handleLocalInputChange}
                                required
                                variant={"blue"}
                            />
                            <Textarea
                                maxLength={250}
                                label={"Description"}
                                name={"description"}
                                value={localFormData.description}
                                onChange={handleLocalInputChange}
                                variant={"blue"}
                            />
                            <Button onClick={handleSubmit} width="full-width" variant={"primary"}>
                                Mettre à jour
                            </Button>
                        </Bento>
                        <Bento highlight="highlight">
                            <Stack separator>
                                <Title level={3} variant="danger">
                                    Supprimer le client <Span textTransform={"capitalize"}>{clientDetails?.name}</Span>
                                </Title>
                            </Stack>
                            <Stack spacing="20px" direction="column">
                                <Text>
                                    Vous pouvez supprimer le client <Span textTransform={"capitalize"}>{clientDetails?.name}</Span>, mais cette action est définitive.
                                </Text>
                                <IconButton variant="danger" onClick={handleDeleteClient}>
                                    <CiTrash />
                                    Supprimer ce client
                                </IconButton>
                            </Stack>
                        </Bento>
                    </Column>
                </Container>
            </Section>
            <ClientLogoPopup
                id={id}
                clientName={clientDetails?.name}
                open={popups['popupLogoClient']}
                logo={logo}
                onProgressChange={onProgressChange}
                onUploadStart={onUploadStart}
                onUploadEnd={onUploadEnd}
                maxSize={3 * 1048576}
                onClose={() => closePopup('popupLogoClient')}
                acceptedTypes="image/png, image/jpeg, image/jpg, image/PNG"
            />
        </>
    );
}
