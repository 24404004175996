import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import TextInput from '../../form/Textinput';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifyError, notifySuccess } from '../../Toastify';
import Tips from '../../textual/Tips';

const EditAuditTemplatePopup = ({ open, onClose, auditTemplate }) => {
    const { editAnAuditTemplate, refresh, setRefresh } = useData();
    const [error, setError] = useState(null);
    const [auditTemplateName, setAuditTemplateName] = useState(auditTemplate.name);
    const [auditTemplateType, setAuditTemplateType] = useState(auditTemplate.type);
    const [auditCycle, setAuditCycle] = useState(auditTemplate.cycleFrequency);

    const handleSubmit = async () => {
        if (auditTemplateName && auditCycle && auditTemplateType) {
            try {
                await editAnAuditTemplate(auditTemplate.id, auditTemplateName, auditTemplateType, auditCycle);
                setRefresh(!refresh);
                onClose();
                notifySuccess("Le template a été mis à jour")
            } catch (error) {
                console.error("Erreur lors de la modification de l'audit :", error);
            }
        } else {
            notifyError("Veuillez remplir tous les champs requis.");
        }
    };

    if(!auditTemplate){
        setError("Aucune tâche")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Modifier le template d'audit ${auditTemplate?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : auditTemplate ? (
                <>
                    <Tips variant="warning">Modifier les informations du template ne modifiera pas les informations des audits déjà créés.</Tips>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de l'audit"}
                                value={auditTemplateName}
                                onChange={(e) => setAuditTemplateName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"text"}
                                label={"Type"}
                                value={auditTemplateType}
                                onChange={(e) => setAuditTemplateType(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"number"}
                                label={"Durée du cycle en jours"}
                                value={auditCycle}
                                onChange={(e) => setAuditCycle(e.target.value)}
                                required
                                variant={"blue"}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucun template d'audit trouvé.</Text>
            )}
        </Popup>
    );
};

export default EditAuditTemplatePopup;
