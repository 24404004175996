import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import TextInput from '../../form/Textinput';
import Textarea from '../../form/Textarea';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifySuccess } from '../../Toastify';

const EditTemplateTaskPopup = ({ open, onClose, taskTemplate }) => {
    const { editATaskTemplate, refresh, setRefresh } = useData();
    const [error, setError] = useState(null);
    const [taskName, setTaskName] = useState(taskTemplate.name);
    const [taskDaysBeforeTargetDay, setDaysBeforeTargetDay] = useState(taskTemplate.daysBeforeTargetDay);
    const [taskDescription, setTaskDescription] = useState(taskTemplate.description);

    const handleSubmit = async () => {
        if (taskName) {
            try {
                await editATaskTemplate(taskTemplate.id, taskName, taskDaysBeforeTargetDay, taskDescription);
                setRefresh(!refresh);
                onClose();
                notifySuccess('La tâche a été mise à jour')
            } catch (error) {
                console.error("Erreur lors de la modification de la tâche :", error);
            }
        } else {
            alert("Veuillez remplir tous les champs requis.");
        }
    };

    if(!taskTemplate){
        setError("Aucune tâche")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Modifier la tâche ${taskTemplate?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : taskTemplate ? (
                <>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de la tâche"}
                                value={taskName}
                                onChange={(e) => setTaskName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"number"}
                                value={taskDaysBeforeTargetDay}
                                onChange={(e) => setDaysBeforeTargetDay(e.target.value)}
                                label={"Délai en jours"}
                                required
                                variant={"blue"}
                            />
                            <Textarea
                                variant={"blue"}
                                label={"Description"}
                                value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)}
                                maxCharCount={250}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default EditTemplateTaskPopup;
