import React from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import CreateTask from '../../form/CreateTask';
import Tips from '../../textual/Tips';

const AddTaskPopup = ({ open, onClose, auditId }) => {
  return (
    <Popup open={open} onClose={onClose} title={"Ajouter une tâche à cet audit"}>
      <Text>Une tâche est une action à réaliser dans un temps imparti, vous pouvez préciser son nom et sa date où elle doit être réalisée au plus tard.</Text>
      <Tips variant="success">Petit conseil : ne surchargez pas les audits de tâches, cela peut décourager les clients.</Tips>
      <CreateTask auditId={auditId} onClose={onClose} />
    </Popup>
  );
};

export default AddTaskPopup;
