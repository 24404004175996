import React from 'react';
import styled from 'styled-components';

const DialogElement = styled.dialog`
  padding: 30px;
  position: fixed;
  top: 50%;
  width: 500px;
  transform: translateY(-50%);
  border: 
  ${props =>
    props.$variant === "danger"
      ? "1px solid var(--error-color);"
      : props.$variant === "success"
      ? "1px solid var(--success-color);"
      : "1px solid var(--grey-color);"};
  background-color: var(--bg-color);
  z-index: 1000; 
  border-radius: 5px;
  overflow: ${props => props.$overflow || "hidden" };

  @media (max-width: 550px) {
    max-width: 90%;
  }
`;

function Dialog({ children, open, onCancel, variant, overflow }) {
  if (!open) return null;

  return (
    <DialogElement open={open} onCancel={onCancel} $variant={variant} $overflow={overflow}>
      {children}
    </DialogElement>
  );
}

export default Dialog;
