import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import { getAudit } from '../../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import LoadingSpinner from "../../../../states/LoadingSpinner";
import TextInput from '../../form/Textinput';
import DateInput from '../../form/DateInput';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifySuccess } from '../../Toastify';
import { dateToAWSFormat } from '../../../../tools/Calculate';
import Tips from '../../textual/Tips';

const client = generateClient();

const EditAuditPopup = ({ open, onClose, auditId }) => {
    const { editAnAudit, refresh, setRefresh } = useData();
    const [audit, setaudit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [auditName, setauditName] = useState('');
    const [auditType, setAuditType] = useState('');
    const [auditTargetDate, setAuditTargetDate] = useState('');

    useEffect(() => {
        if (open && auditId) {
            console.log(auditId)
            fetchauditDetails(auditId);
        }
    }, [open, auditId]);

    async function fetchauditDetails(id) {
        setLoading(true);
        setError(null);
        try {
            const auditData = await client.graphql({
                query: getAudit,
                variables: { id }
            });
            const fetchdaudit = auditData.data.getAudit;
            setaudit(fetchdaudit);

            setauditName(fetchdaudit.name || '');
            setAuditType(fetchdaudit.type || '');
            setAuditTargetDate(fetchdaudit.targetDate || '');
        } catch (error) {
            console.error("Erreur lors de la récupération de l'audit :", error);
            setError("Une erreur est survenue lors de la récupération de l'audit.");
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (auditName && auditTargetDate) {
            try {
                const formattedDate = await dateToAWSFormat(auditTargetDate);
                await editAnAudit(auditId, auditName, auditType, formattedDate);
                setRefresh(!refresh);
                onClose();
                notifySuccess("L'audit a été mise à jour")
            } catch (error) {
                console.error("Erreur lors de la modification de l'audit :", error);
            }
        } else {
            alert("Veuillez remplir tous les champs requis.");
        }
    };

    return (
        <Popup open={open} onClose={onClose} title={`Modifier l'audit ${audit?.name}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <Text variant="error">{error}</Text>
            ) : audit ? (
                <>
                    <Tips variant="success">Vous modifiez les données de l'audit et non ses tâches.</Tips>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de l'audit"}
                                value={auditName}
                                onChange={(e) => setauditName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"text"}
                                label={"Type"}
                                value={auditType}
                                onChange={(e) => setAuditType(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <DateInput
                                type={"text"}
                                label={"Date de la tâche"}
                                value={auditTargetDate}
                                onChange={(e) => setAuditTargetDate(e.target.value)}
                                required
                                variant={"blue"}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucun audit trouvé.</Text>
            )}
        </Popup>
    );
};

export default EditAuditPopup;
