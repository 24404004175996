import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Pastille from '../../textual/Pastille';
import { handlerVariantTaskTemplateDaysBefore } from '../../../../tools/handlerVariantTaskTemplateDaysBefore';

const ViewTemplateTaskPopup = ({ open, onClose, taskTemplate }) => {
    const [error, setError] = useState(null);

    if (!taskTemplate) {
        setError("Aucune tâche")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Vue de la tâche ${taskTemplate?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : taskTemplate ? (
                <>
                    <Pastille variant={handlerVariantTaskTemplateDaysBefore(taskTemplate?.daysBeforeTargetDay)}>
                        {taskTemplate?.daysBeforeTargetDay}j avant l'audit
                    </Pastille>
                    <Text>Description : {taskTemplate?.description}</Text>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default ViewTemplateTaskPopup;
