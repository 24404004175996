import { getProperties  } from 'aws-amplify/storage';

const getContentTypeS3 = async (filePath, identityId) => {
    try {
        if (filePath) {
            const file = await getProperties({
                path: `protected/${identityId}/${filePath}`
            });
            return file.contentType;
        }
    } catch (error) {
        console.error('Error fetching file from S3:', error);
        throw error;
    }
};

export default getContentTypeS3;
