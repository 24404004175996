import MenuItem from '../ui/aside-section/HeaderItems';
import { CiGrid41 } from 'react-icons/ci';
import { PiUser } from "react-icons/pi";


function DefaultMenu() {
    return (
        <ul className="menu">
            <MenuItem text="Tableau de bord" icon={CiGrid41 } variant="classique" to="/" />
            <MenuItem text="Profil" icon={PiUser} variant="classique" to="/profil" />
        </ul>
    );
}

export default DefaultMenu;
