import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledLinkButton = styled(NavLink)`
line-height: ${props => props.$lineheight || ""};
width: fit-content;
color:inherit;
`;

const InvisibleLink = ({ to, children, lineheight, onClick }) => (
    <StyledLinkButton to={to} $lineheight={lineheight} onClick={onClick}>
        {children}
    </StyledLinkButton>
);

export default InvisibleLink;