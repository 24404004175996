import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Tips from '../../textual/Tips';
import BentoCheckbox from '../../checkbox/BentoCheckbox';
import IconButton from '../../button/IconButton';
import { useData } from '../../../../contexts/DataContext';
import { notifyError, notifySuccess } from '../../Toastify';
import { generateClient } from 'aws-amplify/api';
import { useUser } from '../../../../contexts/UserContext';
import getContentTypeS3 from '../../../../hooks/getdata/getContentTypeS3';
import { listEntityDocumentRelations } from '../../../../graphql/queries';
import Stack from '../../../box/container/Stack';
import { handleVariantDocumentType } from '../../../../tools/handleVariantDocumentType';

const client = generateClient();

const AddEntityDocumentRelationPopup = ({ open, onClose, entityId, entityClass, entityName, clientId }) => {
    const [documents, setDocuments] = useState([]);
    const [documentContentTypes, setDocumentContentTypes] = useState({});
    const { user } = useUser();
    const { listAllDocuments, createADocumentEntityRelation, refresh, setRefresh } = useData();
    const [selectedDocuments, setSelectedDocuments] = useState({});

    async function fetchEntityDocuments(entityId, entityClass, documentId) {
        try {
            const response = await client.graphql({
                query: listEntityDocumentRelations,
                variables: {
                    filter: {
                        entityId: { eq: entityId },
                        entityClass: { eq: entityClass },
                        entityDocumentRelationDocumentId: { eq: documentId },
                    },
                },
                authMode: 'lambda',
                authToken: `user_token ${user.accessToken}`,
            });
            return response.data.listEntityDocumentRelations.items.map(relation => relation.document);
        } catch (error) {
            console.error("Erreur lors de la récupération des documents associés :", error);
            return [];
        }
    }


    useEffect(() => {
        async function getDocuments() {
            try {
                const availableDocuments = await listAllDocuments();
                setDocuments(availableDocuments);

                const contentTypes = {};
                for (const document of availableDocuments) {
                    const contentType = await getContentTypeS3(document.path, document.identityId);
                    contentTypes[document.id] = contentType;
                }
                setDocumentContentTypes(contentTypes);
            } catch (error) {
                console.error("Erreur lors de la récupération des documents :", error);
            }
        }

        if (open) {
            getDocuments();
        } else {
            setSelectedDocuments({});
        }
    }, [open, listAllDocuments]);

    const handleDocumentSelection = async (documentId) => {
        const associatedDocuments = await fetchEntityDocuments(entityId, entityClass, documentId);

        if (associatedDocuments.length > 0) {
            notifyError(`Le document ${documentId} est déjà associé à ce client.`);
            return;
        }

        setSelectedDocuments((prevSelected) => ({
            ...prevSelected,
            [documentId]: !prevSelected[documentId],
        }));
    };

    const handleAssignDocuments = async () => {
        const selectedDocumentIds = Object.keys(selectedDocuments).filter((docId) => selectedDocuments[docId]);

        try {
            for (const documentId of selectedDocumentIds) {
                const associatedDocuments = await fetchEntityDocuments(entityId, entityClass, documentId);
                if (associatedDocuments.length === 0) {
                    await createADocumentEntityRelation(entityClass, entityId, documentId);
                } else {
                    notifyError(`Le document ${documentId} est déjà associé à ce client.`);
                }
            }

            setRefresh(!refresh);
            notifySuccess('Document associé avec succès');
            onClose();
        } catch (error) {
            console.error("Erreur lors de l'attribution des documents :", error);
            notifyError("Erreur lors de l'attribution des documents");
        }
    };

    const selectedCount = Object.values(selectedDocuments).filter(Boolean).length;

    return (
        <Popup open={open} onClose={onClose} title={`Associer des documents à ${entityName}`}>
            <Tips variant="success">
                {entityClass === 'client' && `Choisissez les documents que vous voulez associer et afficher sur la fiche client de ${entityName}`}
                {entityClass === 'audit' && `Sélectionnez les documents que vous souhaitez associer à l'audit ${entityName}`}
                {entityClass === 'task' && `Sélectionnez les documents à associer à la tâche ${entityName}`}
            </Tips>

            <div style={{ marginTop: '20px' }}>
                {documents.length > 0 ? (
                    <Stack direction={"column"}>
                        {documents.map((document) => (
                            <BentoCheckbox
                                key={document.id}
                                type="checkbox"
                                checked={selectedDocuments[document.id] || false}
                                onChange={() => handleDocumentSelection(document.id)}
                                label={`${document.name}`}
                                chipVariant={handleVariantDocumentType(documentContentTypes[document.id])}
                                chip={documentContentTypes[document.id] || 'Loading...'}
                            />
                        ))}
                    </Stack>
                ) : (
                    <Text>Aucun document disponible</Text>
                )}
            </div>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    variant="action"
                    onClick={handleAssignDocuments}
                    enable={selectedCount === 0 ? "notactive" : undefined}
                >
                    Associer {selectedCount} documents
                </IconButton>
            </div>
        </Popup>
    );
};

export default AddEntityDocumentRelationPopup;
