import React from 'react';
import Hero from '../../../components/box/section/Hero';
import ThemeSwitch from '../../../Custom/Theme/ThemeSwitch';
import Title from '../../../components/ui/textual/Title';
import Stack from '../../../components/box/container/Stack';


function Appearance() {
    return (
        <Hero>
            <Title level={1}>
                Paramètres
            </Title>
            <Stack separator>
                <Title level={3}>
                    Préférence de thème
                </Title>
            </Stack>
            <Stack spacing="20px">
                <ThemeSwitch />
            </Stack>
        </Hero>
    );
}

export default Appearance;