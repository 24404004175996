import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import UploadLogo from '../../UploadLogo';
import Stack from '../../../box/container/Stack';
import IconButton from '../../button/IconButton';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { IoMdAdd } from 'react-icons/io';
import { confirm } from "../../../confirm/ConfirmGlobal";
import { notifyError, notifyWarning } from '../../Toastify';
import { useUser } from '../../../../contexts/UserContext';

const ClientLogoPopup = ({ id, open, onClose, onProgressChange, onUploadStart, onUploadEnd, clientName, logo }) => {
    const [showUploadSection, setShowUploadSection] = useState(false);
    const { user, isAdmin } = useUser();

    const handleDeletePicture = async () => {
        if (!isAdmin) {
            notifyError("Vous n'avez pas les permissions nécessaires pour effectuer cette action.");
            return;
        }
        try {
            if (await confirm({
                title: `Voulez-vous vraiment supprimer le logo de ${clientName} ?`,
                content: "Le logo sera supprimé définitivement et personne ne pourra y accéder.",
                variant: "danger"
            })) {
                notifyWarning('Bientôt disponible');
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du logo :", error);
            notifyError("Erreur lors de la suppression du logo");
        }
    };

    const handleUpload = () => {
        if (!isAdmin) {
            notifyError("Vous n'avez pas les permissions nécessaires pour effectuer cette action.");
            return;
        }
        setShowUploadSection(true);
    };

    const handleClose = () => {
        setShowUploadSection(false);
        onClose();
    };

    const renderProfilePicture = () => (
        <Stack align={"center"} direction={"column"}>
            {logo ? (
                <img src={logo} className="big-user-picture" alt={user.profile.name} />
            ) : (
                <img src="/illustration/svg/utilisateur.svg" className="big-user-picture" alt="avatar" />
            )}
            <Text>
                {logo 
                    ? `Cliquez sur le bouton ci-dessous pour choisir un nouveau logo pour ${clientName}.`
                    : `Cliquez sur le bouton ci-dessous pour ajouter un logo pour ${clientName}.`
                }
            </Text>
        </Stack>
    );

    const renderActionButtons = () => (
        <Stack justify={"space-between"}>
            <IconButton variant={"action"} onClick={handleUpload}>
                {logo ? <><CiEdit /> Changer de logo</> : <><IoMdAdd /> Ajouter un logo</>}
            </IconButton>
            {logo && (
                <IconButton variant={"danger"} onClick={handleDeletePicture}>
                    <CiTrash /> Supprimer
                </IconButton>
            )}
        </Stack>
    );

    const renderUploadSection = () => (
        <Stack direction={"column"} animationType="moveFromRight">
            <Text>Cliquez sur le bouton ci-dessous pour choisir un nouveau logo pour {clientName}.</Text>
            <UploadLogo
                id={id}
                onProgressChange={onProgressChange}
                onUploadStart={onUploadStart}
                onUploadEnd={onUploadEnd}
                maxSize={3 * 1048576}
                closePopup={handleClose}
                acceptedTypes="image/png, image/jpeg, image/jpg, image/PNG"
            />
            <Text size="sm">Assurez-vous que votre image est au format JPG, PNG, JPEG et qu'elle ne dépasse pas la taille maximale autorisée de 3mo.</Text>
        </Stack>
    );

    return (
        <Popup open={open} onClose={handleClose} title={`Le logo de ${clientName}`}>
            {!showUploadSection ? (
                <Stack direction={"column"}>
                    {renderProfilePicture()}
                    {isAdmin && renderActionButtons()}
                </Stack>
            ) : (
                isAdmin && renderUploadSection()
            )}
        </Popup>
    );
};

export default ClientLogoPopup;
