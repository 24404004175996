import React from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import CreateTaskTemplate from '../../form/CreateTaskTemplate';
import Tips from '../../textual/Tips';

const AddTaskTemplatePopup = ({ open, onClose, auditTemplateId }) => {
    return (
        <Popup open={open} onClose={onClose} title={"Ajouter une tâche à ce template"}>
            <Text>Une tâche de template d'audit n'est pas une tâche en soi, mais elle pourra être utilisée pour les futurs clients à qui vous appliquerez ce template d'audit.</Text>
            <Tips variant="warning">Attention ! Les audits déjà créés depuis ce template n'auront pas cette tâche attribuée !</Tips>
            <CreateTaskTemplate auditTemplateId={auditTemplateId} onClose={onClose} />
        </Popup>
    );
};

export default AddTaskTemplatePopup;

