import React, { useEffect, useState } from 'react';
import { useData } from '../../contexts/DataContext';
import { useUser } from '../../contexts/UserContext';
import ClientsItem from '../ui/aside-section/ClientsItem';
import fetchS3File from '../../hooks/getdata/fetchs3File';
import LoadingSpinner from '../../states/LoadingSpinner';
import Accordion from '../ui/Accordion';
import { useSidebar } from '../../contexts/SideBarContext';
import IconButton from '../ui/button/IconButton';
import { MdAdd } from 'react-icons/md';

function ClientsMenu() {
  const { clients } = useData();
  const { isAdmin } = useUser();
  const [clientLogos, setClientLogos] = useState({});
  const { isAccordionOpen, toggleAccordion } = useSidebar();

  useEffect(() => {
    const fetchLogos = async () => {
      const logos = {};
      if (clients && clients.length > 0) {
        for (const client of clients) {
          if (client.logo) {
            try {
              const url = await fetchS3File(client.logo, client.logoIdentityId);
              logos[client.id] = url;
            } catch (error) {
              console.error('Error fetching client logo:', error);
            }
          }
        }
      }
      setClientLogos(logos);
    };

    fetchLogos();
  }, [clients]);

  const handleAccordionToggle = (isOpen) => {
    toggleAccordion(isOpen);
  };

  return (
    <Accordion
      title="Vos clients"
      defaultOpen={isAccordionOpen}
      chipValue={clients.length}
      highlight={false}
      fontSize='sm'
      variant='compact'
      onToggle={handleAccordionToggle}
    >
      <ul className="menu">
        {clients && clients.length > 0 ? (
          <>
            {clients.map((client) => (
              <ClientsItem
                key={client.id}
                text={client.name}
                logo={clientLogos[client.id] || "/illustration/svg/utilisateur.svg"}
                variant="classique"
                to={`/clients/${client.id}`}
              />
            ))}
            {isAdmin && (
              <IconButton width="100%" variant={"action"} fontSize="16px" to={"/clients/ajouter-un-client"}><MdAdd />Ajouter un client</IconButton>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </ul>

    </Accordion>
  );
}

export default ClientsMenu;
