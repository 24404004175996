import React, { useEffect, useState } from 'react';
import FormContainer from '../../../components/box/container/FormContainer';
import Hero from '../../../components/box/section/Hero';
import FormCreateAudit from '../../../components/ui/form/FormCreateAudit';
import Bento from '../../../components/box/bento/Bento';
import { useParams } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext';
import Loading from '../../../states/Loading';
import Title from '../../../components/ui/textual/Title';
import FormCreateAuditNoTemplate from '../../../components/ui/form/FormCreateAuditNoTemplate';
import IconButton from '../../../components/ui/button/IconButton';
import Stack from '../../../components/box/container/Stack';
import { getClients } from '../../../graphql/queries';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export default function AuditCreationPage() {
  const { listAuditTemplate } = useData();
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState(null);
  const [auditTemplates, setAuditTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createWithTemplate, setCreateWithTemplate] = useState(true);

  useEffect(() => {
    async function fetchData(id) {
      setLoading(true);
      try {
        const client_tmp = await getClient(id);
        setClientDetails(client_tmp);

        const tempAuditTemplates = await listAuditTemplate();
        setAuditTemplates(tempAuditTemplates);
      } catch (error) {
        console.error('Error fetching client or audit templates', error);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchData(id);
    }
  }, [id, listAuditTemplate]);


  async function getClient(id) {
    try {
      const clientTarget = await client.graphql({
        query: getClients,
        variables: { id: id }
      });
      return clientTarget.data.getClients;
    } catch (error) {
      console.error("Erreur lors de la récupération du client :", error);
      return null;
    }
  }

  const handleToggleCreateWithTemplate = () => {
    setCreateWithTemplate(!createWithTemplate);
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  const titleText = createWithTemplate
    ? `Créer un audit avec template pour ${clientDetails && clientDetails.name}`
    : `Créer un audit sans template pour ${clientDetails && clientDetails.name}`;

  return (
    <Hero>
      <FormContainer>
        <Stack direction="column">
          <Bento
            width="500px"
            highlight="highlight"
            padding="40px"
            overflow="visible"
            responsive={{ mobilePadding: '20px' }}
          >
            <Title level={4} variant="white">
              {titleText}
            </Title>
            {createWithTemplate ? (
              <FormCreateAudit auditTemplates={auditTemplates} client={clientDetails} />
            ) : (
              <FormCreateAuditNoTemplate client={clientDetails} />
            )}
          </Bento>
          <IconButton variant="secondary-action" onClick={handleToggleCreateWithTemplate}>
            {createWithTemplate ? 'Créer un audit sans template' : 'Créer un audit avec template'}
          </IconButton>
        </Stack>
      </FormContainer>
    </Hero>
  );
}
