import React from 'react';
import styled, { css } from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import Stack from "../box/container/Stack";
import { Link } from 'react-router-dom';
import Text from './textual/Text';
import Column from '../box/container/Column';

const paddingAndBorderRadiusStyles = css`
    border-radius: ${(props) => (props.$variant === "compact" ? "5px" : "10px")};
    padding: ${(props) => (props.$variant === "compact" ? "12px 18px" : "10px 16px")};
`;

const SettingNavigationContainer = styled(Link)`
    width: 100%;
    color: var(--color-title);
    background: ${(props) => (props.$highlight ? "var(--secondary-bg-color)" : "")};
    position: relative;
`;

const SettingNavigationHeader = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    gap: 10px;
    ${paddingAndBorderRadiusStyles}
    text-decoration: none;
    color: inherit;

    &:hover {
        background: var(--nav-bg-active);
    }
`;

const SettingNavigationContent = styled.div``;

const SettingNavigationChip = styled.span`
    line-height: 0;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    gap: 5px;
    position: relative;
    display: flex;
    color: var(--success-color);
    background: var(--success-bg);
    border: none;
    padding: 5px;
    font-size: 15px;
    border-radius: 50px;
    margin: 0;
`;

const IconContainer = styled.div`
    line-height: 0;
`;

const TextSettingNavigation = styled.p`
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 10px;
    color: var(--color-title);
    font-size: ${(props) => {
        switch (props.$size) {
            case "sm":
                return "15px";
            case "md":
                return "18px";
            case "lg":
                return "20px";
            default:
                return "20px";
        }
    }};
`;

export default function SettingNavigation({ children, title, chipValue, content, highlight = true, size, variant = "default", to }) {
    return (
        <SettingNavigationContainer $highlight={highlight} to={to}>
            <SettingNavigationHeader $variant={variant}>
                <Stack width={"100%"}>
                    <Column width={"30%"}>
                        <TextSettingNavigation $size={size}>
                            {title}
                        </TextSettingNavigation>
                    </Column>
                    <Column width={"70%"}>
                        <Text>{content}</Text>
                    </Column>
                </Stack>
                <Stack align={"center"} justify={"center"} spacing={"5px"}>
                    {chipValue && <SettingNavigationChip>{chipValue}</SettingNavigationChip>}
                    <IconContainer>
                        <MdKeyboardArrowRight size={24} />
                    </IconContainer>
                </Stack>
            </SettingNavigationHeader>
            <SettingNavigationContent>
                {children}
            </SettingNavigationContent>
        </SettingNavigationContainer>
    );
}
