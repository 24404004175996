import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import FormError from '../../form/FormError';
import LoadingSpinner from "../../../../states/LoadingSpinner";
import fetchS3File from '../../../../hooks/getdata/fetchs3File';
import Pastille from '../../textual/Pastille';
import { handleVariantDocumentType } from '../../../../tools/handleVariantDocumentType';
import getContentTypeS3 from '../../../../hooks/getdata/getContentTypeS3';

async function fetchDocumentDetails(document, setLoading, setError, setDocumentUrl) {
    setLoading(true);
    try {
        const file = await fetchS3File(document.path, document.identityId);
        setDocumentUrl(file);
    } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
        setError("Une erreur est survenue lors de la récupération du document.");
    } finally {
        setLoading(false);
    }
}

const ViewDocumentPopup = ({ open, onClose, document, documentType }) => {
    const [loading, setLoading] = useState(false);
    const [documentProperty, setDocumentProperty] = useState("");
    const [documentUrl, setDocumentUrl] = useState(null);
    const [textContent, setTextContent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDocumentPropertyAndDetails = async () => {
            setLoading(true);
            try {
                if (!documentType && document) {
                    const fileProperty = await getContentTypeS3(document.path, document.identityId);
                    setDocumentProperty(fileProperty);
                } else {
                    setDocumentProperty(documentType);
                }
                await fetchDocumentDetails(document, setLoading, setError, setDocumentUrl);
            } catch (err) {
                console.error("Error fetching document details or property:", err);
                setError("An error occurred while fetching the document details.");
            } finally {
                setLoading(false);
            }
        };

        if (open && document) {
            fetchDocumentPropertyAndDetails();
        }
    }, [open, document, documentType]);

    useEffect(() => {
        if (documentProperty === 'text/plain' && documentUrl) {
            fetch(documentUrl)
                .then(response => response.text())
                .then(text => setTextContent(text))
                .catch(err => {
                    console.error("Erreur lors de la récupération du fichier texte :", err);
                    setError("Impossible de lire le fichier texte.");
                });
        }
    }, [documentProperty, documentUrl]);

    const renderDocumentPreview = () => {
        if (!documentProperty || !documentUrl) {
            return <Text>Document non disponible</Text>;
        }

        if (documentProperty.startsWith('image/')) {
            return <img src={String(documentUrl)} alt={`Document ${document?.name}`} style={{ maxWidth: '100%', maxHeight: '500px' }} />;
        } else if (documentProperty === 'application/pdf') {
            return (
                <embed
                    src={String(documentUrl)}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                />
            );
        } else if (documentProperty === 'text/plain') {
            return textContent ? (
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxHeight: '500px', overflow: 'auto', color: 'var(--paragraph)' }}>
                    {textContent}
                </pre>
            ) : (
                <LoadingSpinner />
            );
        } else {
            return <FormError variant="error">La visualisation pour ce type de document n'est pas supportée.</FormError>;
        }
    };

    return (
        <Popup open={open} onClose={onClose} title={`Document ${document?.name}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <Text variant="error">{error}</Text>
            ) : document ? (
                <>
                    <Pastille variant={handleVariantDocumentType(documentProperty)}>
                        {documentProperty}
                    </Pastille>
                    <Text>Description : {document?.description}</Text>
                    {renderDocumentPreview()}
                </>
            ) : (
                <Text>Aucun document trouvé.</Text>
            )}
        </Popup>
    );
};

export default ViewDocumentPopup;
