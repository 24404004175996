import React, { createContext, useContext, useState } from 'react';

const SideBarContext = createContext();

export const SideBarProvider = ({ children }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const toggleAccordion = (isOpen) => {
    setIsAccordionOpen(isOpen);
  };

  return (
    <SideBarContext.Provider value={{ isAccordionOpen, toggleAccordion }}>
      {children}
    </SideBarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(SideBarContext);
};
