import React from "react";
import styled from "styled-components";

const TextComponent = styled.span`
  color: ${(props) =>
    props.$variant === "default"
      ? "var(--paragraph)"
      : props.$variant === "colored"
        ? "var(--main-color)"
        : props.$variant === "white"
          ? "#ADADB0"
          : ""};
  font-size: ${(props) => {
    switch (props.$size) {
      case "sm":
        return "15px";
      case "md":
        return "18px";
      case "lg":
        return "20px";
      default:
        return "";
    }
  }};
  text-transform: ${(props) => props.$textTransform || ""};
`;

const Span = ({ variant, size, textTransform, className, id, onClick, data_cy, children, ...restProps }) => {
  return (
    <TextComponent $variant={variant} $size={size} $textTransform={textTransform} data-cy={data_cy} {...restProps}>
      {children}
    </TextComponent>
  );
};

export default Span;
