import { getUrl } from 'aws-amplify/storage';

const fetchS3File = async (picturePath, identityId) => {
    try {
        if (picturePath) {
            const url = await getUrl({
                path: `protected/${identityId}/${picturePath}`,
                options: {
                    expiresIn: 3600,
                }
            });
            return url.url;
        }
    } catch (error) {
        console.error('Error fetching file from S3:', error);
        throw error;
    }
};

export default fetchS3File;
