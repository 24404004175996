import React, { useEffect, useMemo } from 'react';
import { generateClient } from "aws-amplify/api";
import Hero from "../../../components/box/section/Hero";
import CreateClientForm from "../../../components/ui/form/CreateClientForm";
import { useData } from '../../../contexts/DataContext';
import Bento from '../../../components/box/bento/Bento';
import FormContainer from '../../../components/box/container/FormContainer';

const client = generateClient();

export default function AddClients() {
    const { setFormData } = useData();

    const cleanForm = useMemo(() => ({
        email: "",
        name: "",
        description: "",
    }), []);

    useEffect(() => {
        setFormData(cleanForm);
    }, [cleanForm, setFormData]);

    return (
        <Hero>
            <FormContainer>
                <Bento
                    width="450px"
                    highlight="highlight"
                    padding="40px"
                    responsive={{
                        mobilePadding: "20px"
                    }}
                >
                    <CreateClientForm client={client} />
                </Bento>
            </FormContainer>
        </Hero>
    );
}
