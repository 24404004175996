import React from 'react';
import styled from 'styled-components';

const TextInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input.withConfig({
  shouldForwardProp: (prop) => !['$variant'].includes(prop),
})`
  width: 100%;
  border: solid 1px;
  border-color: ${(props) =>
    props.$variant === 'blue'
      ? 'var(--paragraph)'
      : props.$variant === 'green'
      ? 'var(--main-color)'
      : props.$variant === 'white'
      ? 'var(--bg-color)'
      : 'var(--paragraph)'};
  color: ${(props) =>
    props.$variant === 'blue'
      ? 'var(--paragraph)'
      : props.$variant === 'green'
      ? 'var(--main-color)'
      : props.$variant === 'white'
      ? 'var(--bg-color)'
      : 'var(--paragraph)'};
  padding: 10px 14px;
  font-size: 20px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;

  &:focus {
    border-color: var(--main-color);
    color: var(--main-color);
    outline: none;
  }

  &:not(:placeholder-shown) + label,
  &:focus + label {
    color: var(--main-color);
    transform: translateY(-22px);
    font-size: 0.75rem;
    font-weight: 600;
    background-color: var(--secondary-bg-color);
    opacity: 1;
  }

  &:not(:focus) + label {
    color: var(--color-title);
  }
`;

const Label = styled.label`
  line-height: 1;
  position: absolute;
  left: 9px;
  padding: 0px 5px;
  font-size: 16px;
  color: var(--color-title);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  opacity: 0.7;
`;

const TextInput = ({ label, name, type, variant, className, id, onClick, data_cy, ...restProps }) => {
  return (
    <TextInputContainer>
      <Input
        {...restProps}
        type={type}
        name={name}
        $variant={variant}
        className={className}
        id={id}
        onClick={onClick}
        placeholder=" "
        data-cy={data_cy}
      />
      <Label>{label}</Label>
    </TextInputContainer>
  );
};

export default TextInput;
