import React from 'react';

function Error429() {
    return (
        <>
            <p>Erreur 429 </p>
        </>
    );
}

export default Error429;
