import React from 'react';
import styled from 'styled-components';
import Pastille from '../textual/Pastille';
import Stack from '../../box/container/Stack';

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  line-height: 0;
  background: var(--secondary-bg-color);
  padding: 10px 18px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--paragraph);
`;

const Icon = styled.svg`
  fill: none;
  stroke: var(--bg-color);
  stroke-width: 4px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'var(--main-color)' : 'var(--bg-color)')};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  box-shadow: 0 0 0 1px var(--paragraph);

  &:after{
    transition: 0.3s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--color-title);
    opacity: 0;
    border-radius: 50px;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--colored-text);
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const LabelContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--paragraph);
  max-width: 200px;
`;

const Label = styled.span`
  font-size: 18px;
  color: var(--paragraph);
  padding: 0;
    margin: 0;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    flex-grow: 1;
`;

const BentoCheckbox = ({ className, checked, label, chip, chipVariant, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <Stack spacing={"10px"} align={"center"}>
        <StyledCheckbox checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        <LabelContainer>
          {label && <Label>{label}</Label>}
        </LabelContainer>
      </Stack>
      {chip && <Pastille variant={chipVariant}>{chip}</Pastille>}
    </CheckboxContainer>
  </label>
);

export default BentoCheckbox;
