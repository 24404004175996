import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const commonStyles = css`
  line-height: 0;
  display: flex;
  align-items: center;
  font-size: ${props => props.$size || "24px"};
  width: fit-content;
  cursor: pointer;
  pointer-events: fill;
`;

const classicStyles = css`
  color: var(--paragraph);
`;

const dangerStyles = css`
  color: var(--error-color);
`;

const activeStyles = css`
  color: var(--main-color);
  border-radius: 50%;
`;

const transparentActiveStyles = css`
  color: var(--bg-color);
  border-radius: 50%;
`;

const StyledMenuLink = styled(NavLink)`
  ${(props) => (props.$variant === 'danger' ? dangerStyles : classicStyles)}
  ${(props) => props.to && `&.${props.$activeclassname} { ${props.$iconColorVariant === 'transparent' ? transparentActiveStyles : activeStyles} }`}
  ${commonStyles}
`;

const BottomBarItems = ({ icon: Icon, variant, to, size, onClick, iconColorVariant }) => (
  <StyledMenuLink $size={size} to={to} onClick={onClick} $variant={variant} $activeclassname="active" $iconColorVariant={iconColorVariant}>
    {Icon && <Icon />}
  </StyledMenuLink>
);

export default BottomBarItems;