import React from 'react';
import Popup from '../Popup';
import Tips from '../../textual/Tips';
import CreateDocument from '../../form/CreateDocument';

const AddDocumentPopup = ({ open, onClose }) => {
  return (
    <Popup open={open} onClose={onClose} title={"Ajouter un document"}>
      <Tips variant="success">Ce document pourra ête assigné à des clients, audits, et des tâches.</Tips>
      <CreateDocument />
    </Popup>
  );
};

export default AddDocumentPopup;