import React from 'react';
import Upload from '../ui/form/Upload';
import Stack from '../../components/box/container/Stack';
import { notifyError } from './Toastify';
import { useUser } from '../../contexts/UserContext';
import { PiPaperclipLight } from 'react-icons/pi';

export default function UploadDocument({ onFileSelect, acceptedTypes }) {
    const { isAdmin } = useUser();

    const handleFileChange = (event) => {
        if (!isAdmin) {
            notifyError("Vous n'avez pas les permissions nécessaires pour effectuer cette action.");
            return;
        }
        const selectedFile = event.target.files[0];

        if (onFileSelect) {
            onFileSelect(selectedFile);
        }
    };

    return (
        <Stack direction="column">
            <Upload
                icon={PiPaperclipLight}
                variant="action"
                name="document"
                accept={acceptedTypes}
                onChange={handleFileChange}
                text="Joindre un fichier"
                disabled={!isAdmin}
            />
        </Stack>
    );
}
