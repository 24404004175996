import React from 'react';
import Main from '../components/box/main/Main';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import '../assets/style/style.css';

const LogoutLayout = ({ children }) => {
    const { isLoggedIn } = useUser();

    return (
        <>
            {!isLoggedIn ? (
                <Main variant="wnotsidebar">
                    {children}
                </Main>
            ) : (
                <Navigate to="/" replace />
            )}
        </>
    );
}

export default LogoutLayout;
