import { useState, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext.js';
import TextInput from '../../components/ui/form/Textinput';
import DateInput from '../../components/ui/form/DateInput';
import Bento from '../../components/box/bento/Bento';
import Section from '../../components/box/section/Section';
import Container from '../../components/box/container/Container';
import Title from '../../components/ui/textual/Title';
import Pastille from '../../components/ui/textual/Pastille';
import Button from '../../components/ui/button/Button';
import Text from '../../components/ui/textual/Text';
import Column from '../../components/box/container/Column';
import FormWrapper from '../../components/box/container/FormWrapper';
import BackgroundImageContainer from '../../components/box/container/BackgroundImageContainer';
import IconButton from '../../components/ui/button/IconButton';
import Form from '../../components/ui/form/Form';
import Textarea from '../../components/ui/form/Textarea';
import { dateToAWSFormat } from '../../tools/Calculate';
import {
    notifySuccess,
    notifyError,
} from '../../components/ui/Toastify';
import { CiMail, CiLocationOn, CiBank, CiLinkedin, CiGlobe, CiMedal, CiPhone } from "react-icons/ci";
import { PiCake } from "react-icons/pi";
import TextHover from "../../components/ui/hover/TextHover";
import { updateProfile } from '../../graphqlCustom/mutationsCustom';
import { generateClient } from 'aws-amplify/api';
import { usePopup } from '../../contexts/PopupContext';
import ProfilePicturePopup from '../../components/ui/popup/allPopups/ProfilePicturePopup.js';

const client = generateClient();

const ProfilePage = ({ onProgressChange, onUploadStart, onUploadEnd }) => {
    const { popups, openPopup, closePopup } = usePopup();
    const { user, isLoggedIn, updateUser, profilePictureURL, cognitoGroups } = useUser();
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [bio, setBio] = useState('');
    const [website, setWebsite] = useState('');
    const [city, setCity] = useState('');

    useEffect(() => {
        if (user) {
            setName(user.profile.name);
            setSurname(user.profile.surname);
            setPhoneNumber(user.profile.phone_number);
            setBirthdate(user.profile.birthdate);
            setBio(user.profile.bio);
            setWebsite(user.profile.website);
            setCity(user.profile.city);
        }
    }, [user]);

    useEffect(() => {
        if (cognitoGroups && cognitoGroups.length > 0) {
            if (cognitoGroups.includes("Admin")) {
                setRole("Admin");
            } else if (cognitoGroups.includes("Reltim")) {
                setRole("Membre de Reltim");
            }
        }
    }, [cognitoGroups]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSurnameChange = (event) => {
        setSurname(event.target.value);
    };

    const handleBioChange = (event) => {
        setBio(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };

    const handleBirthdateChange = (event) => {
        setBirthdate(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleUpdateAttributes = async (event) => {
        event.preventDefault();
        try {
            const formattedDate = await dateToAWSFormat(birthdate);
            const updatedProfile = await client.graphql({
                query: updateProfile,
                variables: {
                    input: {
                        id: user.profile.id,
                        owner: user.profile.owner,
                        name: name,
                        surname: surname,
                        phone_number: phone,
                        bio: bio,
                        birthdate: formattedDate,
                        website: website,
                        city: city,
                    }
                }
            });

            console.log('User attributes updated:', updatedProfile);

            const updatedProfileData = updatedProfile.data.updateProfile;

            updateUser({
                ...user,
                profile: {
                    ...user.profile,
                    name: updatedProfileData.name,
                    surname: updatedProfileData.surname,
                    phone_number: updatedProfileData.phone_number,
                    bio: updatedProfileData.bio,
                    birthdate: updatedProfileData.formattedDate,
                    website: updatedProfileData.website,
                    city: updatedProfileData.city,
                }
            });

            console.log('User updated:', user);
            notifySuccess("Profil mis à jour");
        } catch (error) {
            console.log(error);
            notifyError("La modification a échouée");
        }
    };


    if (!isLoggedIn || !user) {
        return <div>Chargement...</div>;
    }

    return (
        <>
            <Section>
                <BackgroundImageContainer
                    coverUrl="/background/cover_4.jpg">
                    <div className="profil-container">
                        <TextHover
                            text={"Changer de Photo de profil"}
                            onClick={() =>
                                openPopup('popupProfilePicture')
                            }
                        >
                            {profilePictureURL ? (
                                <img src={profilePictureURL} className="user-picture" alt={user.profile.name} />
                            ) : (
                                <img src="/illustration/svg/utilisateur.svg" className="user-picture" alt="avatar" />
                            )}
                        </TextHover>

                        <div className="user-info">
                            <div>
                                <Title level={3} variant="white" data_cy={"user-name-surname"}>
                                    {user.profile.name} {user.profile.surname}
                                </Title>
                                <Text variant="white">{role}</Text>
                            </div>
                        </div>
                    </div>
                </BackgroundImageContainer>
            </Section>
            <Section>
                <Container direction="row" align="start" width="100%">
                    <Column width="35%" gap="20px">
                        <Bento highlight="highlight">
                            <Title level={4}>
                                À propos
                            </Title>
                            <Text>{user.profile.bio}</Text>
                            {user.profile.birthdate && user.profile.birthdate !== 0 && (
                                <Pastille variant="success">
                                    <PiCake />{user.age} ans
                                </Pastille>
                            )}
                            {user.profile.email && user.profile.email !== 0 && (
                                <Pastille variant="success">
                                    <CiMail />{user.profile.email}
                                </Pastille>
                            )}
                            {user.profile.phone_number && user.profile.phone_number !== 0 && (
                                <Pastille variant="success">
                                    <CiPhone />{user.profile.phone_number}
                                </Pastille>
                            )}
                            {user.profile.city && user.profile.city !== 0 && (
                                <Pastille variant="success">
                                    <CiLocationOn />{user.profile.city}
                                </Pastille>
                            )}
                            <Pastille variant="success">
                                <CiBank />Devops chez Reltim
                            </Pastille>
                            <Pastille variant="success">
                                <CiMedal />Membre depuis 25 jours
                            </Pastille>
                        </Bento>
                        <Bento highlight="highlight">
                            <Title level={4}>
                                Social
                            </Title>
                            <IconButton variant="secondary-action" as="isLink" href="https://www.linkedin.com/feed/">
                                <CiLinkedin />LinkedIn
                            </IconButton>
                            <IconButton variant="secondary-action" as="isLink" href={user.profile.website}>
                                <CiGlobe />{user.profile.website}
                            </IconButton>
                        </Bento>
                    </Column>
                    <Column width="65%">
                        <Bento highlight="highlight">
                            <Form onSubmit={handleUpdateAttributes}>
                                <Title level={4}>
                                    Informations personnelles
                                </Title>
                                <FormWrapper>
                                    <TextInput
                                        type="text"
                                        variant="blue"
                                        label="Prénom"
                                        value={name}
                                        onChange={handleNameChange}
                                        data_cy={"name_edit_input"}
                                    />
                                    <TextInput
                                        type="text"
                                        variant="blue"
                                        label="Nom"
                                        value={surname}
                                        onChange={handleSurnameChange}
                                        data_cy={"surname_edit_input"}
                                    />
                                    <TextInput
                                        type="text"
                                        variant="blue"
                                        label="Numéro"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                    <DateInput
                                        type="text"
                                        variant="blue"
                                        label="Date de naissance"
                                        value={birthdate}
                                        onChange={handleBirthdateChange}
                                    />
                                    <TextInput
                                        type="text"
                                        variant="blue"
                                        label="Site web"
                                        value={website}
                                        onChange={handleWebsiteChange}
                                    />
                                    <TextInput
                                        type="text"
                                        variant="blue"
                                        label="Ville"
                                        value={city}
                                        onChange={handleCityChange}
                                    />
                                </FormWrapper>
                                <Textarea
                                    variant="blue"
                                    label="Votre bio"
                                    value={bio}
                                    onChange={handleBioChange}
                                    maxCharCount={250}
                                />
                                <Button type='submit' data_cy="submit-edit-profil" width="full-width" variant="primary">Mettre à jour</Button>
                            </Form>
                        </Bento>
                    </Column>
                </Container>
            </Section>
            <ProfilePicturePopup
                open={popups['popupProfilePicture']}
                onClose={() => closePopup('popupProfilePicture')}
                onProgressChange={onProgressChange}
                onUploadStart={onUploadStart}
                onUploadEnd={onUploadEnd}
            />
        </>
    );
}

export default ProfilePage;
