import React, { useState } from 'react';
import { useData } from '../../../contexts/DataContext';
import TextInput from "./Textinput";
import Button from "../button/Button";
import Textarea from './Textarea';
import { notifyError, notifySuccess } from "../Toastify";
import DateInput from "./DateInput";
import { dateToAWSFormat } from "../../../tools/Calculate";
import Stack from '../../box/container/Stack';

export default function CreateTask(props) {
  const { auditId, onClose } = props;
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  const { createATask, refresh, setRefresh } = useData();

  async function handleTask() {
    if (!name || !date) {
      notifyError('Veuillez remplir tous les champs');
      return;
    }
  
    try {
      const formattedDate = await dateToAWSFormat(date);
      await createATask(auditId, null, name, formattedDate, description);
      notifySuccess('Tâche créée');
      setRefresh(!refresh);
      onClose();
    } catch (error) {
      notifyError('Erreur lors de la création de la tâche');
      console.error('Erreur lors de la création de la tâche', error);
    }
  }
  

  return (
    <Stack direction={"column"} spacing={"15px"}>
      <Stack direction={"column"}>
        <TextInput
          type={"text"}
          label={"Nom de la tâche"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant={"blue"}
        />
        <DateInput
          type={"text"}
          label={"Date de la tâche"}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
          variant={"blue"}
        />
        <Textarea label={"description"} maxCharCount={250} onChange={(e) => setDescription(e.target.value)}/>
      </Stack>
      <Button width="full-width" variant={"primary"} onClick={handleTask}>Créer la tâche</Button>
    </Stack>
  )
}
