import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { getUrl } from "aws-amplify/storage";
import Loading from "../states/Loading";
import { getProfile } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';

const UserContext = createContext();
const client = generateClient();

export const UserProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [cognitoGroups, setCognitoGroups] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileCompleted, setProfileCompleted] = useState(null);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        const session = await fetchAuthSession();
        if (session.userSub) {
          const accessToken = session.tokens.accessToken.toString();
          const identityId = session.identityId;
          const currentUser = await getCurrentUser();
          const userProfile = await getUserProfile(currentUser.userId);
          const cognitoGroups = session.tokens.idToken.payload['cognito:groups'];
          setCognitoGroups(cognitoGroups && cognitoGroups.length > 0 ? cognitoGroups : null);
          setIsAdmin(cognitoGroups && cognitoGroups.includes('Admin'));

          setUser({
            age: userProfile.birthdate ? calculateAge(userProfile.birthdate) : 0,
            pseudo: currentUser ? currentUser.username : 'User',
            id: currentUser ? currentUser.userId : 0,
            profile: userProfile,
            identityId: identityId,
            accessToken: accessToken,
          });
          setProfile(userProfile);
          setLoggedIn(true);
          fetchProfilePictureURL(userProfile.profile_picture);

          checkProfileCompletion(userProfile);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'état d\'authentification :', error);
        setLoggedIn(false);
      }
    };

    checkAuthState();
  }, []);

  const refreshUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      const userProfile = await getUserProfile(currentUser.userId);
      const session = await fetchAuthSession();
      const identityId = session.identityId;
      const accessToken = session.tokens.accessToken.toString();
      const cognitoGroups = session.tokens.idToken.payload['cognito:groups'];
      setCognitoGroups(cognitoGroups && cognitoGroups.length > 0 ? cognitoGroups : null);
      setIsAdmin(cognitoGroups && cognitoGroups.includes('Admin'));
  
      setUser({
        age: userProfile.birthdate ? calculateAge(userProfile.birthdate) : 0,
        pseudo: currentUser ? currentUser.username : 'User',
        id: currentUser ? currentUser.userId : 0,
        profile: userProfile,
        identityId: identityId,
        accessToken: accessToken,
      });
      setProfile(userProfile);
      setLoggedIn(true);
      fetchProfilePictureURL(userProfile.profile_picture);
      checkProfileCompletion(userProfile);
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'état d\'authentification :', error);
      setLoggedIn(false);
      throw error;
    }
  };
  
  const login = async () => {
    try {
      refreshUser();
      setLoggedIn(true);
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
      setLoggedIn(false);
      throw error;
    }
  };
  
  const logout = () => {
    setUser(null);
    setLoggedIn(false);
    setProfile(null);
    setProfilePictureURL(null);
    setIsAdmin(false);
  };
  
  const updateUser = async (newUserData) => {
    try {
      setUser({ ...newUserData, age: calculateAge(newUserData.birthdate) });
      await refreshUser();
      console.log('User updated:', user);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
      throw error;
    }
  };
  

  const calculateAge = (birthdate) => {
    if (!birthdate) {
      return 0;
    }

    const parts = birthdate.split('-');
    const birthYear = parseInt(parts[0], 10);
    const birthMonth = parseInt(parts[1], 10) - 1;
    const birthDay = parseInt(parts[2], 10);

    const birthDate = new Date(birthYear, birthMonth, birthDay);

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };


  const fetchProfilePictureURL = async (picturePath) => {
    try {
      if (picturePath) {
        const imageObject = await getUrl({
          path: ({ identityId }) => `private/${identityId}/${picturePath}`,
          options: {
            expiresIn: 3600
          }
        });
        setProfilePictureURL(imageObject.url);
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  const checkProfileCompletion = (profile) => {
    const requiredFields = ['name', 'surname'];
    const isComplete = requiredFields.every(field => profile[field] !== null && profile[field] !== '');
    setProfileCompleted(isComplete);
  };

  async function getUserProfile(id) {
    try {
      const profileTarget = await client.graphql({
        query: getProfile,
        variables: { id: id },
      });
      return profileTarget.data.getProfile;
    } catch (error) {
      console.error('Erreur lors de la récupération du profil utilisateur :', error);
    }
  }

  if (isLoggedIn === null) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{
      isLoggedIn,
      user,
      profile,
      profilePictureURL,
      login,
      logout,
      updateUser,
      refreshUser,
      fetchProfilePictureURL,
      cognitoGroups,
      isAdmin,
      profileCompleted
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};