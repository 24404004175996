import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../states/Loading';

function CompleteProfileRoutes({ children }) {
    const { isLoggedIn, profileCompleted } = useUser();

    if (!isLoggedIn) {
        return <Navigate to="/se-connecter" replace />;
    }

    if (isLoggedIn && profileCompleted === null) {
        return <Loading />;
    }

    if (isLoggedIn && profileCompleted === true) {
        return <Navigate to="/" replace />;
    }

    if (isLoggedIn && profileCompleted === false) {
        return children;
    }
}

export default CompleteProfileRoutes;