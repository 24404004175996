import React, { useEffect, useState } from 'react';
import IconButton from '../../../components/ui/button/IconButton';
import AddDocumentPopup from '../../../components/ui/popup/allPopups/AddDocumentPopup';
import { usePopup } from '../../../contexts/PopupContext';
import { useData } from '../../../contexts/DataContext';
import Title from '../../../components/ui/textual/Title';
import { useUser } from '../../../contexts/UserContext';
import Stack from '../../../components/box/container/Stack';
import { CiEdit, CiExport, CiTrash } from 'react-icons/ci';
import { IoMdAdd } from 'react-icons/io';
import Table from '../../../components/ui/table/Table';
import Th from '../../../components/ui/table/Th';
import Checkbox from '../../../components/ui/checkbox/CheckboxItem';
import Td from '../../../components/ui/table/Td';
import Hero from '../../../components/box/section/Hero';
import Link from '../../../components/ui/textual/Link';
import ViewDocumentPopup from '../../../components/ui/popup/allPopups/ViewDocumentPopup';
import getContentTypeS3 from '../../../hooks/getdata/getContentTypeS3';
import Pastille from '../../../components/ui/textual/Pastille';
import { handleVariantDocumentType } from '../../../tools/handleVariantDocumentType';

export default function ListDocuments() {
  const { popups, openPopup, closePopup } = usePopup();
  const { listAllDocuments, refresh, deleteADocument, setRefresh } = useData();
  const [documentProperties, setDocumentProperties] = useState({});
  const { isAdmin } = useUser();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const docs = await listAllDocuments();
        setDocuments(docs);
        docs.forEach((doc) => fetchDocumentType(doc));
      } catch (error) {
        console.error("Erreur lors de la récupération des documents :", error);
      }
    }

    fetchDocuments();
  }, [refresh, listAllDocuments]);

  async function fetchDocumentType(doc) {
    try {
      if (doc.path) {
        const fileProperty = await getContentTypeS3(doc.path, doc.identityId);
        setDocumentProperties((prevProps) => ({
          ...prevProps,
          [doc.id]: fileProperty,
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du type de document :", error);
    }
  }

  async function handleDeleteDocument(id, path) {
    await deleteADocument(id, path);
    setRefresh(!refresh);
  }

  return (
    <Hero>
      <Stack direction="column" spacing="10px">
        <Stack justify="space-between">
          <Title level={1}>Liste des documents</Title>
          <Stack spacing="10px">
            {isAdmin && (
              <IconButton
                fontSize="16px"
                type="submit"
                width="fit-content"
                variant="danger"
              >
                <CiTrash /> Supprimer
              </IconButton>
            )}
            <IconButton fontSize="16px" width="fit-content" variant="secondary-action">
              <CiExport /> Télécharger
            </IconButton>
            {isAdmin && (
              <IconButton
                variant="action"
                onClick={() => openPopup("add_document")}
                fontSize="16px"
              >
                <IoMdAdd /> Ajouter un Document
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Table>
        <thead>
          <tr>
            <Th variant="basique">
              <Checkbox type="checkbox" />
            </Th>
            <Th variant="basique">Nom du document</Th>
            <Th variant="basique">Type de document</Th>
            {isAdmin && <Th variant="basique">Action</Th>}
          </tr>
        </thead>
        <tbody>
          {documents.length === 0 ? (
            <tr>
              <Td colSpan={isAdmin ? 4 : 3}>
                <Title>Aucun document disponible.</Title>
              </Td>
            </tr>
          ) : (
            documents.map((document) => (
              <tr key={document.id}>
                <ViewDocumentPopup
                  open={popups[`view ${document?.id}`]}
                  onClose={() => closePopup(`view ${document?.id}`)}
                  document={document}
                  documentType={documentProperties[document.id]}
                />
                <Td>
                  <Checkbox type="checkbox" />
                </Td>
                <Td>
                  <Link onClick={() => openPopup(`view ${document.id}`)}>
                    {document.name}
                  </Link>
                </Td>
                <Td>
                  <Pastille variant={handleVariantDocumentType(documentProperties[document.id])}>
                    {documentProperties[document.id] || 'Chargement...'}
                  </Pastille>
                </Td>

                {isAdmin && (
                  <Td>
                    <Stack>
                      <IconButton variant="secondary-action">
                        <CiEdit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteDocument(document.id, document.path)}
                        variant="danger"
                        aria-label="Supprimer"
                      >
                        <CiTrash />
                      </IconButton>
                    </Stack>
                  </Td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <AddDocumentPopup
        open={popups["add_document"]}
        onClose={() => closePopup("add_document")}
      />
    </Hero>
  );
}
