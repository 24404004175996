import React, { useState, useEffect } from 'react';
import { generateClient } from "aws-amplify/api";
import Stack from '../../../components/box/container/Stack';
import Hero from "../../../components/box/section/Hero";
import Table from "../../../components/ui/table/Table";
import Th from "../../../components/ui/table/Th";
import Td from "../../../components/ui/table/Td";
import Checkbox from "../../../components/ui/checkbox/CheckboxItem";
import { useData } from '../../../contexts/DataContext';
import { confirm } from "../../../components/confirm/ConfirmGlobal";
import Pastille from "../../../components/ui/textual/Pastille";
import IconButton from "../../../components/ui/button/IconButton";
import { CiTrash, CiEdit, CiCircleRemove, CiClock2 } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import {
  notifySuccess,
  notifyError,
} from '../../../components/ui/Toastify';
import AnimationComponent from "../../../components/animation/Animation";
import Empty from '../../../components/animation/storageAnimation/empty.json';
import Text from '../../../components/ui/textual/Text';
import LoadingAnimation from '../../../components/animation/storageAnimation/empty.json';
import Link from "../../../components/ui/textual/Link";
import TextInput from "../../../components/ui/form/Textinput";
import { useUser } from '../../../contexts/UserContext';
import { listClients } from "../../../graphqlCustom/queriesCustom";
import Select from '../../../components/ui/form/Select';
import Tips from '../../../components/ui/textual/Tips';
import fetchS3File from '../../../hooks/getdata/fetchs3File';
import InvisibleLink from '../../../components/ui/button/InvisibleLink';
import SmallCircleProgress from '../../../components/ui/progress-bar/SmallCircleProgress';

const client = generateClient();

export default function Clients() {
  const {
    clients,
    deleteClient,
    setPressed,
    setBoolNext,
    setBoolPrev,
    setItemsPerPage,
    setTokens,
    setIndexToken,
    indexToken,
    Tokens
  } = useData();
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [deleteButtonState, setDeleteButtonState] = useState("notactive");
  const [itemsPerPage, setItemsPerPageState] = useState(10);
  const { isAdmin } = useUser();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [logoUrls, setLogoUrls] = useState({});

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeout);
    };
  }, [typingTimeout]);

  const handleSearchChange = (event) => {
    const name = event.target.value;
    setSearchQuery(name);

    clearTimeout(typingTimeout);
    setLoading(false);
    setSearching(true);

    if (name.trim().length > 0) {
      setTypingTimeout(setTimeout(() => {
        setLoading(true);
        fetchResults(name.trim().toLowerCase());
        setSearching(false);
      }, 1000));
    } else {
      setSearchResults([]);
      setSearching(false);
    }
  };

  const fetchResults = async (name) => {
    const filter = {
      filter: {
        name: {
          contains: name
        }
      },
      limit: 10
    };

    try {
      const clientsResult = await client.graphql({
        query: listClients,
        variables: filter
      });

      const clients = clientsResult.data.listClients.items;

      setSearchResults(clients);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des résultats de recherche :', error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCount > 0) {
      setDeleteButtonState("active");
    } else {
      setDeleteButtonState("notactive");
    }
  }, [selectedCount]);

  useEffect(() => {
    setLoading(false);
  }, [clients]);

  useEffect(() => {
    const fetchLogos = async () => {
      const logoPromises = clients.map(client => fetchS3File(client.logo, client.logoIdentityId));
      const logoUrls = await Promise.all(logoPromises);
      const logoMap = {};
      clients.forEach((client, index) => {
        logoMap[client.id] = logoUrls[index];
      });
      setLogoUrls(logoMap);
    };

    if (clients.length > 0) {
      fetchLogos();
    }
  }, [clients]);

  const handleChildCheckboxChange = (clientId) => {
    const updatedCheckedItems = { ...checkedItems, [clientId]: !checkedItems[clientId] };
    const count = Object.values(updatedCheckedItems).filter((value) => value).length;
    setSelectedCount(count);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(count === clients.length);
  };

  const handleParentCheckboxChange = () => {
    const updatedCheckedItems = {};
    if (!allChecked) {
      clients.forEach((client) => {
        updatedCheckedItems[client.id] = true;
      });
    }
    setSelectedCount(Object.keys(updatedCheckedItems).length);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(!allChecked);
  };

  const deleteCheckedClients = async () => {
    const checkedClientIds = Object.keys(checkedItems).filter((clientId) => checkedItems[clientId]);
    try {
      if (await confirm({
        title: "Voulez-vous vraiment supprimer ces clients ?",
        content: "Ces clients seront supprimés définitivement et personne ne pourra y accéder",
        variant: "danger"
      })) {
        await Promise.all(checkedClientIds.map(async (clientId) => {
          await deleteClient(clientId);
          setPressed(true);
        }));
        setCheckedItems({});
        setAllChecked(false);
        setSelectedCount(0);
        notifySuccess(`${checkedClientIds.length} clients supprimés avec succès`);
      }
    } catch (error) {
      console.log("error on deleting clients", error);
      notifyError("Erreur lors de la suppression des clients");
    }
  };

  async function handleDeleteClient(id) {
    if (await confirm({
      title: "Voulez-vous vraiment supprimer ce client ?",
      content: "Ce client sera supprimé définitivement et personne ne pourra y accéder",
      variant: "danger"
    })) {
      try {
        await deleteClient(id);
        setPressed(true);
      } catch (error) {
        console.log("error on deleting client", error);
        notifyError("Erreur lors de la suppression du client");
      }
    }
  }

  function itemPerPage(nbr) {
    setItemsPerPage(nbr);
    setTokens([null]);
    setIndexToken(0);
    setPressed(true);
  }

  function handleSelectItemsPerPage(option) {
    itemPerPage(option.id);
    setItemsPerPageState(option.id);
  }

  function nextPage() {
    setBoolNext(true);
  }

  function prevPage() {
    setBoolPrev(true);
  }

  const itemsPerPageOptions = [
    { id: 5, label: '5 clients par page' },
    { id: 10, label: '10 clients par page' },
    { id: 20, label: '20 clients par page' },
    { id: 50, label: '50 clients par page' },
    { id: 100, label: '100 clients par page' },
  ];

  return (
    <Hero>
      <Stack justify="end">
        <TextInput
          type="text"
          label="Rechercher"
          required
          variant={"blue"}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Stack justify="end" width={"100%"}>
          <Select
            options={itemsPerPageOptions}
            value={`${itemsPerPage} clients par page`}
            onSelect={(option) => handleSelectItemsPerPage(option)}
            defaultText="Nombre d'items par page"
          />
          {isAdmin && (
            <Stack spacing={"10px"}>
              <IconButton
                type='submit'
                enable={deleteButtonState}
                width="fit-content"
                variant="danger"
                onClick={deleteCheckedClients}
              >
                <CiTrash /> Supprimer ({selectedCount})
              </IconButton>
              <IconButton variant="action" to="/clients/ajouter-un-client"><IoMdAdd />Ajouter un
                client
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Table>
        <thead>
          <tr>
            {isAdmin && (
              <Th variant="basique">
                <Checkbox
                  type="checkbox"
                  checked={allChecked}
                  onChange={handleParentCheckboxChange}
                />
              </Th>
            )}
            <Th variant="basique">Clients</Th>
            <Th variant="basique">Audits</Th>
            <Th variant="basique">Tâches</Th>
            <Th variant="basique">Progression</Th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <Td colSpan={7}>
                <Stack direction="column" align="center">
                  <AnimationComponent animationData={LoadingAnimation} width="150px" />
                  <Text>Chargement...</Text>
                </Stack>
              </Td>
            </tr>
          )}
          {!loading && searchResults.length === 0 && !searching && searchQuery.length > 0 && (
            <tr>
              <Td colSpan={7}>
                <Stack direction="column" align="center">
                  <Text>Aucun client trouvé pour "{searchQuery}"</Text>
                </Stack>
              </Td>
            </tr>
          )}
          {!loading && searchResults.length === 0 && !searching && !searchQuery.trim() && clients.length === 0 && (
            <tr>
              <Td colSpan={7}>
                <Stack direction="column" align="center">
                  <AnimationComponent animationData={Empty} width="150px" />
                  <Text>Vous n'avez pas encore de client.</Text>
                  {isAdmin ? (
                    <IconButton variant="action" to="/clients/ajouter-un-client">
                      <IoMdAdd />Ajouter un client
                    </IconButton>
                  ) : (
                    <Tips variant={"warning"}>Vous n'avez pas les droits pour ajouter un client, contactez un admin</Tips>
                  )}
                </Stack>
              </Td>
            </tr>
          )}
          {!loading && (searchQuery.trim() ? searchResults : clients).length > 0 && (
            (searchQuery.trim() ? searchResults : clients).map((client) => (
              <tr key={client.id}>
                {isAdmin && (
                  <Td>
                    <Checkbox
                      type="checkbox"
                      checked={checkedItems[client.id] || false}
                      onChange={() => handleChildCheckboxChange(client.id)}
                    />
                  </Td>
                )}
                <Td>
                  <Stack align={"center"}>
                    <InvisibleLink to={`/clients/${client.id}`} lineheight={"0"}>
                      <img
                        src={logoUrls[client.id] ? (logoUrls[client.id]) : ("/illustration/svg/utilisateur.svg")}
                        alt={`${client.name} logo`}
                        style={{ width: '20px', height: '20px', borderRadius: '50px' }}
                      />
                    </InvisibleLink>
                    <Link textTransform={"capitalize"} to={`/clients/${client.id}`}>{client.name}</Link>
                  </Stack>
                </Td>
                {client.audits && client.audits.items && client.audits.items.length > 0 ? (
                  <>
                    <Td>
                      <Pastille variant={"primary"}>
                        <CiClock2 />
                        {client.audits.items.length} audits
                      </Pastille>
                    </Td>
                    <Td>
                      <Pastille variant={"primary"}>
                        <CiClock2 />
                        {
                          client.audits.items.reduce((totalTasks, audit) => {
                            return totalTasks + (audit.tasks && audit.tasks.items ? audit.tasks.items.length : 0);
                          }, 0)
                        } tâches
                      </Pastille>
                    </Td>
                    <Td>
                      {(() => {
                        const totalTasks = client.audits.items.reduce((total, audit) => total + (audit.tasks?.items?.length || 0), 0);
                        const doneTasks = client.audits.items.reduce((done, audit) => done + (audit.tasks?.items?.filter(task => task.done).length || 0), 0);
                        const progress = totalTasks > 0 ? (doneTasks / totalTasks) * 100 : 0;

                        return <SmallCircleProgress showDecimals={false} progress={progress} />;
                      })()}
                    </Td>
                  </>
                ) : (
                  <>
                    <Td>
                      <Pastille variant={"danger"}><CiCircleRemove /> Aucun audit</Pastille>
                    </Td>
                    <Td>
                      <Pastille variant={"danger"}><CiCircleRemove />  Aucune tâche</Pastille>
                    </Td>
                    <Td>
                      <SmallCircleProgress showDecimals={false} progress={0} />
                    </Td>
                  </>
                )}
                {isAdmin && (
                  <Td>
                    <Stack spacing="10px">
                      <IconButton to={`/clients/${client.id}/modifier`} variant={"secondary-action"} wtext="no">
                        <CiEdit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClient(client.id)} variant={"danger"} wtext="no">
                        <CiTrash />
                      </IconButton>
                    </Stack>
                  </Td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Stack justify={"space-between"}>
        <Text variant="white">Page {indexToken + 1}</Text>
        <Stack justify="end">
          {indexToken !== 0 && (
            <IconButton variant="action" onClick={prevPage}>Page précédente</IconButton>
          )}
          {Tokens[indexToken + 1] && (
            <IconButton variant="action" onClick={nextPage}>Page Suivante</IconButton>
          )}
        </Stack>
      </Stack>
    </Hero>
  );
}
