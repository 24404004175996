import React, { useState } from 'react';
import Hero from '../../../components/box/section/Hero';
import Title from '../../../components/ui/textual/Title';
import Bento from '../../../components/box/bento/Bento';
import Stack from '../../../components/box/container/Stack';
import PasswordInput from '../../../components/ui/form/Password';
import Form from '../../../components/ui/form/Form';
import Button from '../../../components/ui/button/Button';
import { updatePassword, updateUserAttributes, confirmUserAttribute } from 'aws-amplify/auth';
import { notifySuccess, notifyError } from '../../../components/ui/Toastify';
import FormError from '../../../components/ui/form/FormError';
import FormWrapper from '../../../components/box/container/FormWrapper';
import Tips from '../../../components/ui/textual/Tips';
import TextInput from '../../../components/ui/form/Textinput';
import IconButton from '../../../components/ui/button/IconButton';
import { useUser } from '../../../contexts/UserContext';
import { updateProfile } from '../../../graphqlCustom/mutationsCustom';
import { generateClient } from 'aws-amplify/api';
import Section from '../../../components/box/section/Section';

const client = generateClient();

function Authentification() {
    const { user, updateUser } = useUser();

    // States pour mot de passe
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState("");

    // States pour email
    const [updatedEmail, setUpdatedEmail] = useState("");
    const [confirmationCode, setConfirmationCode] = useState('');
    const [errorEmail, setErrorEmail] = useState("");
    const [showEmailForm, setShowEmailForm] = useState(true);
    const [showConfirmForm, setShowConfirmForm] = useState(false);
    const [backButtonClicked, setBackButtonClicked] = useState(false);

    // Changement de mot de passe
    const handleInputChangePassword = () => {
        setErrorPassword(null);
    };

    async function handleUpdatePassword() {
        if (!oldPassword || !newPassword || !confirmNewPassword) {
            setErrorPassword("Veuillez remplir tous les champs de mot de passe.");
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setErrorPassword("Les mots de passe ne correspondent pas");
            return;
        }

        try {
            await updatePassword({ oldPassword, newPassword });
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
            notifySuccess("Mot de passe changé");
        } catch (err) {
            notifyError("Le mot de passe n'a pas été changé");
            setErrorPassword("Erreur lors de la mise à jour du mot de passe");
        }
    }

    // Changement d'email
    const handleInputChangeEmail = () => {
        setErrorEmail(null);
    };

    const handleUpdateEmail = async () => {
        if (!updatedEmail) {
            setErrorEmail("Veuillez saisir votre nouvel email");
            return;
        }

        try {
            const result = await updateUserAttributes({
                userAttributes: { email: updatedEmail },
            });

            if (!result.email.isUpdated && result.email.nextStep && result.email.nextStep.updateAttributeStep === 'CONFIRM_ATTRIBUTE_WITH_CODE') {
                setShowConfirmForm(true);
                setShowEmailForm(false);
            }
        } catch (error) {
            notifyError("La modification a échoué");
        }
    };

    const handleConfirmEmailChange = async () => {
        try {
            await confirmUserAttribute({ userAttributeKey: 'email', confirmationCode });
            await client.graphql({
                query: updateProfile,
                variables: {
                    input: { id: user.profile.id, email: updatedEmail },
                },
            });
            updateUser({ ...user, email: updatedEmail });
            notifySuccess("Votre Email a été changé");
        } catch (error) {
            setErrorEmail("Erreur lors de la confirmation du changement d'email.");
            notifyError("Changement du mail échoué");
        }
    };

    const handleBack = () => {
        setShowConfirmForm(false);
        setShowEmailForm(true);
        setBackButtonClicked(true);
    };

    return (
        <Hero>
            <Title level={1}>Paramètres</Title>
            <Section margin={"80px 0px 0px 0px"}>
                <Title level={4}>Changer l'email</Title>
                <Bento padding={"0px"}>
                    {user.profile.email && (
                        <Tips variant="success">Votre email actuel est {user.profile.email}</Tips>
                    )}

                    <FormWrapper>
                        {showEmailForm && (
                            <Stack direction="column" animationType={backButtonClicked ? "moveFromLeft" : "fadeIn"}>
                                <TextInput
                                    variant="blue"
                                    type="email"
                                    label="Nouvel email"
                                    value={updatedEmail}
                                    onChange={(e) => { setUpdatedEmail(e.target.value); handleInputChangeEmail(); }}
                                />
                                {errorEmail && <FormError variant="error">{errorEmail}</FormError>}
                                <Button variant="primary" onClick={handleUpdateEmail}>Étape suivante</Button>
                            </Stack>
                        )}

                        {!showEmailForm && showConfirmForm && (
                            <Stack direction="column" animationType="moveFromRight">
                                <TextInput
                                    variant="blue"
                                    type="number"
                                    label="Code de confirmation"
                                    value={confirmationCode}
                                    onChange={(e) => setConfirmationCode(e.target.value)}
                                />
                                {errorEmail && <FormError variant="error">{errorEmail}</FormError>}
                                <Stack align="center" justify="flex-end">
                                    <IconButton variant="secondary-action" onClick={handleBack}>Retour</IconButton>
                                    <Button variant="primary" onClick={handleConfirmEmailChange}>Valider le code</Button>
                                </Stack>
                            </Stack>
                        )}
                    </FormWrapper>

                </Bento>
            </Section>
            <Section margin={"80px 0px 0px 0px"}>
                <Title level={4}>Changer le mot de passe</Title>
                <Tips variant="success">Pensez à choisir un mot de passe d'au moins 8 caractères.</Tips>
                <FormWrapper>
                    <Form>
                        <PasswordInput
                            variant="blue"
                            label="Mot de passe actuel"
                            value={oldPassword}
                            onChange={(e) => { setOldPassword(e.target.value); handleInputChangePassword(); }}
                        />
                        <PasswordInput
                            variant="blue"
                            label="Nouveau mot de passe"
                            value={newPassword}
                            onChange={(e) => { setNewPassword(e.target.value); handleInputChangePassword(); }}
                        />
                        <PasswordInput
                            variant="blue"
                            label="Confirmer le nouveau mot de passe"
                            value={confirmNewPassword}
                            onChange={(e) => { setConfirmNewPassword(e.target.value); handleInputChangePassword(); }}
                        />
                        {errorPassword && <FormError variant="error">{errorPassword}</FormError>}
                        <Button variant="primary" onClick={handleUpdatePassword}>Mettre à jour le mot de passe</Button>
                    </Form>
                </FormWrapper>
            </Section>
        </Hero>
    );
}

export default Authentification;