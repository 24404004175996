import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useData } from '../../../../contexts/DataContext';
import Text from "../../../../components/ui/textual/Text";
import Section from "../../../../components/box/section/Section";
import Loading from "../../../../states/Loading";
import Stack from '../../../../components/box/container/Stack';
import IconButton from '../../../../components/ui/button/IconButton';
import { useUser } from '../../../../contexts/UserContext';
import AuditCard from "../../../../components/AuditCard";
import { IoMdAdd } from "react-icons/io";
import AnimationComponent from '../../../../components/animation/Animation';
import Empty from '../../../../components/animation/storageAnimation/empty.json';
import Tips from '../../../../components/ui/textual/Tips';
import fetchS3File from '../../../../hooks/getdata/fetchs3File';
import NotFound from '../../../../states/NotFound';
import Span from '../../../../components/ui/textual/Span';
import { getClients } from '../../../../graphql/queries';
import { getAudit } from '../../../../graphqlCustom/queriesCustom';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export default function GetAudits() {
    const { refresh } = useData();
    const { isAdmin } = useUser();
    const [clientDetails, setClientDetails] = useState(null);
    const [audits, setAudits] = useState(null);
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState(null);
    const { clientId, auditId } = useParams();

    async function getClient(id) {
        try {
            const clientTarget = await client.graphql({
                query: getClients,
                variables: { id: id }
            });
            return clientTarget.data.getClients;
        } catch (error) {
            console.error("Erreur lors de la récupération du client :", error);
            return null;
        }
    }

    async function getAuditDetail(id) {
        try {
            const auditTarget = await client.graphql({
                query: getAudit,
                variables: { id: id }
            });
    
            const audit = auditTarget.data.getAudit;
            if (audit && audit.tasks && audit.tasks.items) {
                audit.tasks.items.sort((a, b) => new Date(a.date) - new Date(b.date));
                // audit.tasks.items.sort((a, b) => new Date(b.date) - new Date(a.date));
            }
    
            return audit;
        } catch (error) {
            console.error("Erreur lors de la récupération de l'audit :", error);
            return null;
        }
    }
    

    useEffect(() => {
        async function fetchClientData() {
            setLoading(true);
            try {
                const clientData = await getClient(clientId);
                if (clientData) {
                    setClientDetails(clientData);
                    if (clientData.logo && !logo) {
                        const logoUrl = await fetchS3File(clientData.logo, clientData.logoIdentityId);
                        setLogo(logoUrl);
                    }
                    if (auditId) {
                        const auditData = await getAuditDetail(auditId);
                        if (auditData) {
                            setAudits(auditData);
                        } else {
                            setAudits([]);
                        }
                    }
                } else {
                    setClientDetails(null);
                }
            } catch (e) {
                console.error("Erreur lors de la récupération du client", e);
                setClientDetails(null);
            } finally {
                setLoading(false);
            }
        }

        if (clientId) {
            fetchClientData();
        }
    }, [clientId, refresh, logo, auditId]);

    if (!clientDetails && loading) {
        return (
            <Loading />
        );
    }

    if (!clientDetails && !loading) {
        return (
            <NotFound />
        );
    }

    return (
        <Section>
            <Stack direction={"column"} spacing={"10px"}>
                <Stack direction={"column"} spacing={"30px"}>
                    {audits ? (
                        <AuditCard audit={audits} logo={logo} clientId={clientId} clientName={clientDetails.name} />
                    ) : (
                        <Stack direction="column" align="center">
                            <AnimationComponent animationData={Empty} width="150px" />
                            <Text>
                                <Span textTransform={"capitalize"}>{clientDetails.name}</Span> n'a aucun audit
                            </Text>
                            {isAdmin ? (
                                <IconButton variant="action" to={`/clients/${clientDetails.id}/ajouter-un-audit`}>
                                    <IoMdAdd /> Ajouter un audit
                                </IconButton>
                            ) : (
                                <Tips variant={"warning"}>
                                    Vous n'avez pas les droits pour ajouter un audit, contactez un admin
                                </Tips>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Section>
    );
}
