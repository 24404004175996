import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ChipButtonWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  pointer-events: fill;
`;

const ChipButtonComponent = styled.button`
  line-height: 1;
  justify-content: center;
  height: fit-content;
  text-align: center;
  gap: 5px;
  display: flex;
  align-items: center;
  width: ${(props) => props.$width || "fit-content"};
  color: ${(props) =>
        props.$variant === "action"
            ? "var(--success-color)"
            : props.$variant === "secondary-action"
                ? "var(--colored-text)"
                : props.$variant === "basique"
                    ? "var(--paragraph)"
                    : props.$variant === "danger"
                        ? "var(--error-color)"
                        : props.$variant === "warning"
                            ? "var(--warning-color)"
                            : "none"};
  background: ${(props) =>
        props.$variant === "action"
            ? "var(--success-bg)"
            : props.$variant === "secondary-action"
                ? "var(--nav-bg-hover)"
                : props.$variant === "basique"
                    ? "var(--nav-bg-hover)"
                    : props.$enable === "notactive"
                        ? "none"
                        : props.$variant === "danger"
                            ? "var(--error-bg)"
                            : props.$variant === "warning"
                            ? "var(--warning-bg)"
                            : "none"};
  border: none;
  padding: ${(props) =>
        props.$wtext === "yes"
            ? "10px 14px"
            : props.$wtext === "no"
                ? "10px"
                : "10px 14px"};
  font-size: ${(props) => props.$fontSize || "20px"};
  border-radius: 50px;
  filter: ${(props) =>
    props.$enable === "active"
      ? "none"
      : props.$enable === "notactive"
      ? "grayscale(100%);"
      : "none"};
  cursor: ${(props) =>
    props.$enable === "active"
      ? "pointer"
      : props.$enable === "notactive"
      ? "default"
      : "pointer"};
  opacity: ${(props) =>
    props.$enable === "active"
      ? "1"
      : props.$enable === "notactive"
      ? "0.5"
      : "1"};
  transition: 0.3s;

  &:hover {
    background: ${(props) =>
        props.$variant === "action"
            ? "var(--success-bg-darker)"
            : props.$variant === "secondary-action"
                ? "var(--nav-bg-hover)"
                : props.$variant === "basique"
                    ? "var(--nav-bg-hover)"
                    : props.$enable === "notactive"
                        ? "none"
                        : props.$variant === "danger"
                            ? "var(--error-bg)"
                            : "none"};
  }

  &:active {
    background: ${(props) =>
        props.$variant === "action"
            ? "var(--success-bg-darker)"
            : props.$variant === "secondary-action"
                ? "var(--nav-bg-active)"
                : props.$variant === "basique"
                    ? "var(--nav-bg-active)"
                    : props.$enable === "notactive"
                        ? "none"
                        : props.$variant === "danger"
                            ? "var(--error-bg-darker)"
                            : "none"};
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  top: -12px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--paragraph);
  font-size: 25px; 
  padding: 0;
  transition: 0.3s;

  &:hover {
    color: var(--error-color);
  }
`;

const ChipButton = ({
    type,
    variant,
    enable,
    wtext,
    width,
    className,
    id,
    onClick,
    to,
    fontSize,
    children,
    position,
    right,
    left,
    top,
    bottom,
    transform,
    deleteOnClick,
    ...props
}) => {
    const isLink = to !== undefined && to !== null;

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        if (deleteOnClick) {
            deleteOnClick();
        }
    };

    return (
        <ChipButtonWrapper>
            <ChipButtonComponent
                as={isLink ? Link : "button"}
                to={isLink ? to : null}
                type={isLink ? null : (type ? (type === "input" ? ["button", "input"] : type) : "button")}
                $wtext={wtext}
                $variant={variant}
                $enable={enable}
                $width={width}
                className={className ? `btn-component ${className}` : "btn-component"}
                id={id}
                onClick={!isLink && enable !== "notactive" ? onClick : null}
                $position={position}
                $right={right}
                $left={left}
                $bottom={bottom}
                $top={top}
                $transform={transform}
                $fontSize={fontSize}
                {...props}
            >
                {children}
            </ChipButtonComponent>
            {deleteOnClick && (
                <DeleteButton onClick={handleDeleteClick} aria-label="Supprimer">
                    &times;
                </DeleteButton>
            )}
        </ChipButtonWrapper>
    );
};

export default ChipButton;
