import { useState, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext.js';
import TextInput from '../../components/ui/form/Textinput';
import Hero from '../../components/box/section/Hero';
import Bento from '../../components/box/bento/Bento';
import Title from '../../components/ui/textual/Title';
import Button from '../../components/ui/button/Button';
import { notifySuccess, notifyError } from '../../components/ui/Toastify';
import { CiCamera } from "react-icons/ci";
import TextHover from "../../components/ui/hover/TextHover";
import { updateProfile } from '../../graphqlCustom/mutationsCustom';
import { generateClient } from 'aws-amplify/api';
import Form from '../../components/ui/form/Form';
import IconButton from '../../components/ui/button/IconButton';
import Stack from '../../components/box/container/Stack';
import FormContainer from '../../components/box/container/FormContainer';
import { useNavigate } from 'react-router-dom';
import FormError from '../../components/ui/form/FormError';
import { usePopup } from '../../contexts/PopupContext';
import ProfilePicturePopup from '../../components/ui/popup/allPopups/ProfilePicturePopup.js';
const client = generateClient();

const CompleteProfile = ({ onProgressChange, onUploadStart, onUploadEnd }) => {
  const { popups, openPopup, closePopup } = usePopup();
  const { user, isLoggedIn, updateUser, profilePictureURL } = useUser();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user.profile.name);
      setSurname(user.profile.surname);
      setPhoneNumber(user.profile.phone_number);
    }
  }, [user]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleUpdateAttributes = async (event) => {
    event.preventDefault();

    // Validation des champs
    if (!name || !surname || !phone) {
      setError("Tous les champs sont obligatoires.");
      return;
    }

    try {
      const updatedProfile = await client.graphql({
        query: updateProfile,
        variables: {
          input: {
            id: user.profile.id,
            owner: user.profile.owner,
            name: name,
            surname: surname,
            phone_number: phone,
          }
        }
      });

      console.log('User attributes updated:', updatedProfile);

      const updatedProfileData = updatedProfile.data.updateProfile;

      await updateUser({
        ...user,
        profile: {
          ...user.profile,
          name: updatedProfileData.name,
          surname: updatedProfileData.surname,
          phone_number: updatedProfileData.phone_number,
        }
      });

      console.log('User updated:', user);
      notifySuccess("Profil validé");
      navigate('/');
    } catch (error) {
      console.log(error);
      notifyError("La modification a échouée");
    }
  };


  if (!isLoggedIn || !user) {
    return <div>Chargement...</div>;
  }

  return (
    <>
      <Hero>
        <FormContainer direction="row" align="start" width="100%">
          <Bento width="450px" highlight="highlight" padding="40px"
            responsive={{
              mobilePadding: "20px"
            }}>
            <Form onSubmit={handleUpdateAttributes}>
              <Title level={3}>
                Nous avons besoin de quelques informations
              </Title>
              <Stack align="center" justify={"center"}>
                {profilePictureURL ? (
                  <TextHover
                    text="Changer de Photo de profil"
                    onClick={() =>
                      openPopup('popupProfilePicture')
                    }
                  >
                    <img src={profilePictureURL} className="user-picture" alt="Profile" />
                  </TextHover>
                ) : (
                  <IconButton
                    variant="action"
                    wtext="no"
                    onClick={() =>
                      openPopup('popupProfilePicture')
                    }
                  >
                    <CiCamera />
                  </IconButton>
                )}
              </Stack>
              <TextInput
                type="text"
                variant="blue"
                label="Prénom"
                value={name}
                onChange={handleNameChange}
              />
              <TextInput
                type="text"
                variant="blue"
                label="Nom"
                value={surname}
                onChange={handleSurnameChange}
              />
              <TextInput
                type="text"
                variant="blue"
                label="Numéro de téléphone"
                value={phone}
                onChange={handlePhoneChange}
              />
              <Button type='submit' width="full-width" variant="primary">Valider</Button>
              {error && <FormError variant="error">{error}</FormError>}
            </Form>
          </Bento>
        </FormContainer>
        <ProfilePicturePopup
          open={popups['popupProfilePicture']}
          onClose={() => closePopup('popupProfilePicture')}
          onProgressChange={onProgressChange}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
        />
      </Hero>
    </>
  );
}

export default CompleteProfile;