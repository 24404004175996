/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuditTemplate = /* GraphQL */ `
  query GetAuditTemplate($id: ID!) {
    getAuditTemplate(id: $id) {
      id
      name
      description
      type
      cycleFrequency
      tasksTemplates {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuditTemplates = /* GraphQL */ `
  query ListAuditTemplates(
    $filter: ModelAuditTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTasksTemplate = /* GraphQL */ `
  query GetTasksTemplate($id: ID!) {
    getTasksTemplate(id: $id) {
      id
      name
      description
      daysBeforeTargetDay
      createdAt
      updatedAt
      auditTemplateTasksTemplatesId
      __typename
    }
  }
`;
export const listTasksTemplates = /* GraphQL */ `
  query ListTasksTemplates(
    $filter: ModelTasksTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        daysBeforeTargetDay
        createdAt
        updatedAt
        auditTemplateTasksTemplatesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReminderEmailTemplate = /* GraphQL */ `
  query GetReminderEmailTemplate($id: ID!) {
    getReminderEmailTemplate(id: $id) {
      id
      subject
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReminderEmailTemplates = /* GraphQL */ `
  query ListReminderEmailTemplates(
    $filter: ModelReminderEmailTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminderEmailTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        body
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClients = /* GraphQL */ `
  query GetClients($id: ID!) {
    getClients(id: $id) {
      id
      logo
      logoIdentityId
      email
      name
      description
      audits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocuments = /* GraphQL */ `
  query GetDocuments($id: ID!) {
    getDocuments(id: $id) {
      id
      path
      identityId
      name
      description
      entityRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        path
        identityId
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEntityDocumentRelation = /* GraphQL */ `
  query GetEntityDocumentRelation($id: ID!) {
    getEntityDocumentRelation(id: $id) {
      id
      entityId
      entityClass
      document {
        id
        path
        identityId
        name
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      documentsEntityRelationId
      entityDocumentRelationDocumentId
      __typename
    }
  }
`;
export const listEntityDocumentRelations = /* GraphQL */ `
  query ListEntityDocumentRelations(
    $filter: ModelEntityDocumentRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityDocumentRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        entityClass
        createdAt
        updatedAt
        documentsEntityRelationId
        entityDocumentRelationDocumentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      auditTemplateID {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      name
      type
      status
      targetDate
      realDate
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      clientsAuditsId
      auditAuditTemplateIDId
      __typename
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        status
        targetDate
        realDate
        createdAt
        updatedAt
        clientsAuditsId
        auditAuditTemplateIDId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTasks = /* GraphQL */ `
  query GetTasks($id: ID!) {
    getTasks(id: $id) {
      id
      tasksTemplateID {
        id
        name
        description
        daysBeforeTargetDay
        createdAt
        updatedAt
        auditTemplateTasksTemplatesId
        __typename
      }
      name
      description
      date
      done
      reminderEmailTemplateID {
        id
        subject
        body
        createdAt
        updatedAt
        __typename
      }
      sendReminderEmailDaysBeforeDate
      reminderEmailSentAt
      createdAt
      updatedAt
      auditTasksId
      tasksTasksTemplateIDId
      tasksReminderEmailTemplateIDId
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        date
        done
        sendReminderEmailDaysBeforeDate
        reminderEmailSentAt
        createdAt
        updatedAt
        auditTasksId
        tasksTasksTemplateIDId
        tasksReminderEmailTemplateIDId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientsContact = /* GraphQL */ `
  query GetClientsContact($id: ID!) {
    getClientsContact(id: $id) {
      id
      picture
      client_id {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      name
      surname
      phone_number
      email
      birthdate
      createdAt
      updatedAt
      clientsContactClient_idId
      __typename
    }
  }
`;
export const listClientsContacts = /* GraphQL */ `
  query ListClientsContacts(
    $filter: ModelClientsContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        picture
        name
        surname
        phone_number
        email
        birthdate
        createdAt
        updatedAt
        clientsContactClient_idId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      client {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      profileClientId
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $id: ID
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        surname
        email
        phone_number
        bio
        profile_picture
        city
        website
        birthdate
        owner
        createdAt
        updatedAt
        profileClientId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profileByName = /* GraphQL */ `
  query ProfileByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        surname
        email
        phone_number
        bio
        profile_picture
        city
        website
        birthdate
        owner
        createdAt
        updatedAt
        profileClientId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientsStatus = /* GraphQL */ `
  query GetClientsStatus($id: ID!) {
    getClientsStatus(id: $id) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientsStatuses = /* GraphQL */ `
  query ListClientsStatuses(
    $filter: ModelClientsStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
